import React, { useState, useEffect } from 'react';
import classes from './CreateSalesAcct.module.css';
import "../../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { useModal } from '../../ExchangeRate/GlobalContext';
// import { useModal } from './GlobalContext';


import LatestDash from '../../LatestDashboard/LatestDash';
import CurrencyInput from 'react-currency-input-field';

const initialState = () => {
  return [{ items: '', description: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', amount: '' }];
};

const initialState1 = () => {
  return [{ services: '', services2: '', description: '', amount: '', vat: '', discountPercentage: '', discount: '', totalAmount: '' }];
};


export default function CreateQuote() {
  const [user, setUser] = useState("");
  const [showss, setShowss] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show112, setShow112] = useState(false);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [officeAddress, setOfficeAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [glMethod, setGlMethod] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [receivables, setReceivables] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [selectedRep, setSelectedRep] = useState(null);
  const [sICode, setSICode] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState('');
  const [description1, setDescription1] = useState('');
  const [incs, setIncs] = useState([]);
  const [rates, setRates] = useState('');
  const [shortname, setShortName] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalAmount1, setTotalAmount1] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [debitCode, setDebitCode] = useState('');
  const [debitAmount, setDebitAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const handleClosess = () => setShowss(false);
  // const handleClose = () => setShow(false);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShow1(false);
  const { handleShow } = useModal();
  // const handleShow = () => useModal();
  const handleShowss = () => setShowss(true);
  const handleShow1 = () => setShow1(true);
  const [formData, setFormData] = useState(initialState);
  const [formData121, setFormData121] = useState([]);
  const [formData1, setFormData1] = useState(initialState1);
  const [itemList, setItemList] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState('');
  const [totalDiscount1, setTotalDiscount1] = useState('');
  const [totalTax, setTotalTax] = useState('');
  const [totalTax1, setTotalTax1] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currency, setCurrency] = useState([]);
  const [currLoading, setCurrLoading] = useState(false);


  // const [selectedTax, setSelectedTax] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedExpiryDate, setSelectedExpiryDate] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    product: false,
    service: false,
  });



  const handleSelection = (option) => {
    setSelectedOption((prevState) => ({
      product: option === 'product' ? !prevState.product : false,
      service: option === 'service' ? !prevState.service : false,
    }));
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);




  const handleCustomerChange = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedCustomer(selectedOption.value);
    }
  };

  const handleSalesChange = (selectedOption) => {
    const selectedSaleRepId = selectedOption.value;
    setSelectedRep(selectedSaleRepId);
  };


  useEffect(() => {
    const calculatedTotalAmount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount(calculatedTotalAmount.toFixed(2))
  }, [formData]);

  useEffect(() => {
    const calculatedTotalAmount1 = formData1.reduce(
      (acc, curr) => acc + (parseFloat(curr.totalAmount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount1(calculatedTotalAmount1.toFixed(2))
  }, [formData1]);


  useEffect(() => {
    const calculatedDiscount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount(calculatedDiscount.toFixed(2))
  }, [formData]);

  useEffect(() => {
    const calculatedDiscount1 = formData1.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount1(calculatedDiscount1.toFixed(2))
  }, [formData1]);

  useEffect(() => {
    const calculateTotalVat = () => {
      let totalVatAmount = 0;

      formData.forEach(row => {
        const unitPrice = parseFloat(row.unitPrice) || 0;
        const qty = parseFloat(row.qty) || 0;
        const discountPercentage = parseFloat(row.discountPercentage.replace('%', '')) || 0;
        const discountAmount = (discountPercentage / 100) * (unitPrice * qty);
        const vatRate = row.vat ? parseFloat(row.vat.rate) || 0 : 0;

        // Calculate initial price
        const initialPrice = (unitPrice * qty) - discountAmount;

        // Calculate VAT based on the initial price
        const vatAmount = initialPrice * (vatRate / 100);

        // Accumulate the VAT amount
        totalVatAmount += vatAmount;
      });

      setTotalTax(totalVatAmount.toFixed(2));
    };

    calculateTotalVat();
  }, [formData]);

  useEffect(() => {
    const calculateTotalVat1 = () => {
      let totalVatAmount1 = 0;

      formData1.forEach(row => {
        const amount = parseFloat(row.amount) || 0;
        const discountPercentage = parseFloat(row.discountPercentage.replace('%', '')) || 0;
        const discountAmount = (discountPercentage / 100) * (amount);
        const vatRate = row.vat ? parseFloat(row.vat.rate) || 0 : 0;

        // Calculate initial price
        const initialPrice = (amount) - discountAmount;

        // Calculate VAT based on the initial price
        const vatAmount = initialPrice * (vatRate / 100);

        // Accumulate the VAT amount
        totalVatAmount1 += vatAmount;
      });

      setTotalTax1(totalVatAmount1.toFixed(2));
    };

    calculateTotalVat1();
  }, [formData1]);




  useEffect(() => {
    const calculatedDebit = parseFloat(debitAmount || 0)
    setTotalDebit(calculatedDebit.toFixed(2))
  }, [debitAmount]);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };




  const fetchIncs = async () => {
    setIsLoading(true);


    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-category-id?category_id=${1}`, { headers });
      const itemss = response.data?.data;

      // const filteredItemss = itemss.filter((item) => item.type === '1');

      const options22 = itemss.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setIncs(options22);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const createSalesInvoice = async () => {
    setCreateLoading(true);

    try {
      const formDatas = new FormData();
      // const selectedMergedData = selectedOption.product ? mergedData : mergedData1;
      const optionType = selectedOption.product ? 'product' : selectedOption.service ? 'service' : '';
      const idKey = selectedOption.product ? 'item_id' : 'account_id';

        const services = formData1.map((row) => row.services.value).filter((id) => id !== undefined);
        const services2 = formData1.map((row) => row.services2.value).filter((id) => id !== undefined);
        // const items2 = selectedMergedData.map(item => item.itemId2);
        const descriptions = formData1.map((row) => row.description).filter((id) => id !== undefined);
        const serviceAmount = formData1.map((row) => row.amount).filter((id) => id !== undefined);
        const totalServiceAmount = formData1.map((row) => row.totalAmount).filter((id) => id !== undefined);
        const taxx1 = formData1.map((row) => row.vat.value).filter((id) => id !== undefined);
        const discc1 = formData1.map((row) => row.discount).filter((id) => id !== undefined);
        const disccPercent1 = formData1
          .map((row) => row.discountPercentage?.replace('%', ''))
          .filter((id) => id !== undefined);

        taxx1.forEach((tax, index) => {
          formDatas.append(`tax_id[${index}]`, tax);
        });       

        discc1.forEach((discount, index) => {
          formDatas.append(`discount[${index}]`, discount);
        });

        services.forEach((name, index) => {
          formDatas.append(`account_id[${index}]`, name);
        });

        services2.forEach((name, index) => {
          formDatas.append(`debit_gl_code[${index}]`, name);
        });

        descriptions.forEach((description, index) => {
          formDatas.append(`description[${index}]`, description);
        });

        serviceAmount.forEach((amount, index) => {
          formDatas.append(`service_amount[${index}]`, amount);
        });
        totalServiceAmount.forEach((totalService, index) => {
          formDatas.append(`amount[${index}]`, totalService);
        });

        disccPercent1.forEach((discountPercent, index) => {
          formDatas.append(`discount_percentage[${index}]`, discountPercent);
        });



      formDatas.append('date', selectedDate);
      formDatas.append('expiring_date', selectedExpiryDate);
      formDatas.append('document_number', sICode);
      formDatas.append('customer_id', selectedCustomer.value);
      formDatas.append('sales_rep', selectedRep);
      formDatas.append('reference', description);
      formDatas.append('total_amount', selectedOption.product ? totalAmount : totalAmount1);
      formDatas.append('total_vat', selectedOption.product ? totalTax : totalTax1);
      formDatas.append('total_discount', selectedOption.product ? totalDiscount : totalDiscount1);
      formDatas.append('option_type', "service");
      formDatas.append('transaction_date', transactionDate);
      formDatas.append('currency', selectedCurrency.value || '');

      const response = await axios.post(
        `${BASE_URL}/quotes/create`,
        formDatas,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      fetchInvoiceCode();
      // Reset form fields
      setSICode("");
      setSelectedCustomer("");
      setDebitCode("");
      setSelectedAccountName("");
      setDebitAmount("");
      setDescription("");
      setSelectedDate("");
      setSelectedExpiryDate("");
      setFormData([]);
      setSelectedRep("");

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const fetchGlMethod = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setGlMethod(resultss);

      // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/customer/no-pagination`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const custome = response.data?.data;
      const customs = custome.map(item => ({
        label: item.name,
        value: item.id
      }));
      setCustomerList(customs);
      // setAddress(custome);

      // console.log(custome, "itss");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchGlMethod();
      fetchCustomers();
    }
  }, [bearer]);




  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);




  const goBack = () => {
    navigate(-1);
  }






  const addRow = () => {
    const newRow = {
      items: '', description: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', totalPrice: ''
    };
    setFormData([...formData, newRow]);
  };

  const addRow1 = () => {
    const newRow1 = {
      service: '', services2: '', description: '', amount: '', vat: '', discountPercentage: '', discount: '', totalAmount: ''
    };
    setFormData1([...formData1, newRow1]);
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const deleteRow1 = (index) => {
    const updatedData1 = formData1.filter((_, i) => i !== index);
    setFormData1(updatedData1);
  };



  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const itemss = response.data?.data;

      const filteredItems = itemss.filter((item) => item.type === '2');

      const options1 = filteredItems.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReceivables = async () => {
    setIsLoading(true);


    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-class-id?class_id=${4}`, { headers });
      const itemss = response.data?.data;



      const options2 = itemss.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setReceivables(options2);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIncomes = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resp = response.data?.data;
      const optp = resp.map((item) => ({
        label: item.gl_name,
        value: item.id
      }));
      setIncomes(optp);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const fetchTax = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/taxes/company-tax`, { headers });
      const itemss = response.data?.data;
      const seTax = itemss.map((item) => ({
        label: `${item.short_name} - ${item.rate}`,
        value: item.id,
        rate: item.rate
      }));
      setTaxes(seTax);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTaxes([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchSalesRep = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/sales_rep`, { headers });
      const itemss = response.data?.data;

      const sales = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setSalesRep(sales);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setSalesRep([]);
    } finally {
      setIsLoading(false);
    }
  };





  const handleItemDescriptionChange = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    const selectedUnitPrice = selectedItem?.price || 0;
    handleFormChange(selectedOption, "items", rowIndex);
    handleFormChange(selectedUnitPrice, "unitPrice", rowIndex);
  };

  const handleItemDescriptionChange1 = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    // const selectedAmount = selectedItem?.price || 0;
    handleFormChange1(selectedOption, "services", rowIndex);
    // handleFormChange1(selectedAmount, "amount", rowIndex);
  };

  const handleItemDescriptionChange2 = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    // const selectedAmount = selectedItem?.price || 0;
    handleFormChange1(selectedOption, "services2", rowIndex);
    // handleFormChange1(selectedAmount, "amount", rowIndex);
  };

  const handleFormChange = (value, fieldName, rowIndex) => {
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      const currentRow = updatedFormData[rowIndex];

      if (fieldName === 'vat') {

        if (value.value === 'add-new') {
          handleShow();
          return updatedFormData; // Exit early after showing the modal
        } else {
          currentRow[fieldName] = value; // Assign the selected VAT
        }
      } else {
        currentRow[fieldName] = value; // Handle other field updates
      }

      // Calculate total price based on qty and unitPrice
      const qty = parseFloat(currentRow.qty) || 0;
      const unitPrice = parseFloat(currentRow.unitPrice) || 0;
      let initialPrice = qty * unitPrice;

      // Calculate discount
      let discountAmount = 0;
      if (fieldName === 'discountPercentage' || fieldName === 'discount') {
        if (fieldName === 'discountPercentage') {
          const discountPercentage = parseFloat(value.replace('%', '')) || 0;
          discountAmount = (discountPercentage / 100) * initialPrice;
          currentRow.discount = discountAmount.toFixed(2);
        } else if (fieldName === 'discount') {
          discountAmount = parseFloat(value) || 0;
          const discountPercentage = (discountAmount / initialPrice) * 100;
          currentRow.discountPercentage = discountPercentage.toString();
        }
      } else {
        const discountPercentage = parseFloat(currentRow.discountPercentage.replace('%', '')) || 0;
        discountAmount = (discountPercentage / 100) * initialPrice;
        currentRow.discount = discountAmount.toFixed(2);
      }

      initialPrice -= discountAmount; // Apply discount to initial price


      // Calculate VAT and add to initial price
      const selectedTax = currentRow.vat;
      const vatRate = selectedTax ? parseFloat(selectedTax.rate) || 0 : 0;
      const vatAmount = (vatRate / 100) * initialPrice;
      const totalPrice = initialPrice + vatAmount;

      currentRow.amount = totalPrice.toFixed(2);

      return updatedFormData;
    });
  };

  const handleFormChange1 = (value, fieldName, rowIndex) => {
    setFormData1(prevFormData => {
      const updatedFormData1 = [...prevFormData];
      const currentRow1 = updatedFormData1[rowIndex];

      if (fieldName === 'vat') {

        if (value.value === 'add-new') {
          handleShow();
          return updatedFormData1; // Exit early after showing the modal
        } else {
          currentRow1[fieldName] = value; // Assign the selected VAT
        }
      } else {
        currentRow1[fieldName] = value; // Handle other field updates
      }

      // Calculate total price based on qty and unitPrice
      const amount = parseFloat(currentRow1.amount) || 0;
      let initialPrice = amount;

      // Calculate discount
      let discountAmount = 0;
      if (fieldName === 'discountPercentage' || fieldName === 'discount') {
        if (fieldName === 'discountPercentage') {
          const discountPercentage = parseFloat(value.replace('%', '')) || 0;
          discountAmount = (discountPercentage / 100) * initialPrice;
          currentRow1.discount = discountAmount.toFixed(2);
        } else if (fieldName === 'discount') {
          discountAmount = parseFloat(value) || 0;
          const discountPercentage = (discountAmount / initialPrice) * 100;
          currentRow1.discountPercentage = discountPercentage.toString();
        }
      } else {
        const discountPercentage = parseFloat(currentRow1.discountPercentage.replace('%', '')) || 0;
        discountAmount = (discountPercentage / 100) * initialPrice;
        currentRow1.discount = discountAmount.toFixed(2);
      }

      initialPrice -= discountAmount; // Apply discount to initial price

      // Calculate VAT and add to initial price
      const selectedTax = currentRow1.vat;
      const vatRate = selectedTax ? parseFloat(selectedTax.rate) || 0 : 0;
      const vatAmount = (vatRate / 100) * initialPrice;
      const totalPrice = initialPrice + vatAmount;

      currentRow1.totalAmount = totalPrice.toFixed(2);

      return updatedFormData1;
    });
  };









  const fetchAcctName = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAccountName(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAcctName();
      fetchItems();
      fetchTax();
      fetchSalesRep();
      fetchReceivables();
      fetchIncomes();
      fetchIncs();
    }
  }, [bearer]);


  const formatNumber = (value) => {
    if (!value) return '';
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const parseNumber = (value) => {
    return value.replace(/,/g, '');
  };





  const extendedCustomerList = [
    { label: '(Add a new customer)', value: 'add-new' },
    ...customerList
  ];

  const extendedSalesRep = [
    { label: '(Add a new sales rep)', value: 'add-new' },
    ...salesRep
  ];

  const extendedTaxes = [
    { label: '(Add a new VAT)', value: 'add-new' },
    ...taxes
  ];


  const showCustomer = (selectedCustomer) => {
    if (selectedCustomer.value === 'add-new') {
      handleShowss();
    }
    return selectedCustomer;
  };

  const createCustomer = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/customer/add`,
        {
          name: fullName,
          email: email,
          phone: phone,
          address: address,
          office_address: officeAddress,
        },
        { headers }
      );
      console.log(response.data.message)
      const newCustomer = {
        label: response.data.data.name,
        value: response.data.data.id
      };

      console.log(newCustomer);

      setCustomerList([...customerList, newCustomer]);
      setSelectedCustomer(newCustomer.value);

      fetchCustomers();
      setFullName('');
      setEmail('');
      setPhone('');
      setAddress('');
      setOfficeAddress('');
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      // toast.error(JSON.stringify(error.response.data.message));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const createSalesRep = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/sales_rep/create`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          address: address,
        },
        { headers }
      );
      console.log(response.data.message)

      fetchSalesRep();
      setFullName('');
      setEmail('');
      setPhone('');
      setAddress('');
      handleClose1();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      // toast.error(JSON.stringify(error.response.data.message));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };



  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTransactionDate = (event) => {
    setTransactionDate(event.target.value);
  };
  const handleExpiryChange = (event) => {
    setSelectedExpiryDate(event.target.value);
  };

  // console.log(selectedCustomer);

  const handleSalesRep = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow1();
    } else {
      setSelectedRep(selectedOption.value);
    }
  };

  const handleVat = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setTotalTax(selectedOption.value);
    }
  };


  const createRate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/taxes/create`,
        {
          description: description1,
          short_name: shortname,
          rate: rates
        },
        { headers }
      );

      console.log(response)

      setDescription1('');
      setShortName('');
      setRates('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // toast.success(response.data.message);
      console.log(response.data);

      fetchTax();
      handleClose();

      // toast.success(response.data.message);
      console.log(response);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrency = async () => {
    setCurrLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
      const results = response.data?.data;
      // console.log(results, "currencis")
      const options = results.map((item) => ({
        label: item.code,
        value: item.id,
        symbol: item.symbol
      }));
      setCurrency(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCurrency([]);
    } finally {
      setCurrLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCurrency();
    }
  }, [bearer]);

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption.value);
  };


  return (
    <div>
      <ToastContainer />
      <LatestDash />

      <div className={classes.finishedbodyCont}>



        <h3 className={classes.productText}>Create Quote</h3>
        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={classes.formLabel}>
            </div>
            <Modal show={showss} onHide={handleClosess} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      // autoFocus
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Office Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter office Address"
                      // autoFocus
                      value={officeAddress}
                      onChange={(e) => setOfficeAddress(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClosess}>
                  Go back
                </Button>
                <Button variant="success" onClick={createCustomer}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Sales Rep</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      // autoFocus
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>
                <Button variant="success" onClick={createSalesRep}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <Modal show={show112} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add New VAT</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Short Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Short Name"
                      // autoFocus
                      value={shortname}
                      onChange={(e) => setShortName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Rate</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Rate"
                      // autoFocus
                      value={rates}
                      onChange={(e) => setRates(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createRate}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Modal.Footer>
            </Modal> */}


            <div className="d-flex justify-content-center">
              <Form style={{ marginTop: 20, width: '90%' }}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Quote To</Form.Label>
                        <div style={{ width: 500 }}>
                          <Select loading={loading} options={extendedCustomerList} placeholder='Select Customer' value={selectedCustomer} onChange={(selectedOption) => setSelectedCustomer(showCustomer(selectedOption))}
                            menuPortalTarget={document.body}
                            styles={{

                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                maxWidth: '700px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Quote Number</Form.Label>
                        <Form.Control
                          className={classes.formControl}
                          type="text"
                          // placeholder="Enter Description of the Product"
                          value={sICode}
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Reference</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          name="description"
                        // style={{ width: "1000px" }}
                        />

                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6" >
                    <div className="d-flex align-items-center form-group-custom">
                      <Form.Label className={classes.formLabelCustom}>Supporting Document</Form.Label>
                      <Form.Group className="mb-3">
                        <div >

                          <Form.Control
                            type="file"
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={handleImageChange}
                            style={{ width: 349, display: "flex", justifyContent: "left" }}
                          />

                        </div>
                      </Form.Group>
                    </div>
                  </div>

                </div>
                <div className="row" style={{ marginTop: -10, }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Date</Form.Label>
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleDateChange}
                          name="date"
                          value={selectedDate}

                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Expiry Date</Form.Label>
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleExpiryChange}
                          name="date"
                          value={selectedExpiryDate}

                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Sales Rep</Form.Label>
                        <div style={{ width: 500 }}>
                          <Select
                            menuPortalTarget={document.body}

                            styles={{
                              width: 355,
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                maxWidth: '700px',
                                overflowY: 'auto',
                              }),
                            }}
                            loading={loading} options={extendedSalesRep} placeholder='Select Sales Rep' onChange={handleSalesRep} />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Transaction Date</Form.Label>
                        <div style={{ width: 500 }}>
                          <input
                            className="form-control"
                            required=""
                            type="date"
                            onChange={handleTransactionDate}
                            name="date"
                            value={transactionDate}

                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>

                </div>
                <div className="row" style={{ marginTop: -20 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Currency</Form.Label>
                        {/* {formData.map((row, index) => ( */}
                        {/* // <tr > */}
                        {/* <td style={{ width: "200px" }}> */}
                        <div style={{ width: 500 }}>
                          <Select
                            value={selectedCurrency}
                            onChange={setSelectedCurrency}
                            options={selectOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                // maxWidth: '200px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                        {/* </td> */}
                        {/* </tr> */}
                        {/* ))} */}

                      </div>
                    </Form.Group>
                  </div>

                </div>

              </Form>
            </div>

            {/* <div style={{ padding: 20 }}>
              <h5>Quote for:</h5>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <div>
                  <input
                    type="checkbox"
                    id="product"
                    name="product"
                    checked={selectedOption.product}
                    onChange={() => handleSelection('product')}
                  />
                  <label htmlFor="product" style={{ marginLeft: 5 }}>Product/Item</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="service"
                    name="service"
                    checked={selectedOption.service}
                    onChange={() => handleSelection('service')}
                  />
                  <label htmlFor="service" style={{ marginLeft: 5 }}>Service</label>
                </div>
              </div>
            </div> */}

            <div className={classes.buttonsGroup}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                {/* {selectedOption.product && (
                  <div className="row" style={{ maxWidth: "100%", marginTop: 10 }}>
                    <h5 style={{ textAlign: "left" }}>Add Item(s)</h5>
                    <div className="table-responsive">
                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">
                        <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                          <tr>
                            <th style={{ width: "200px" }}>Item</th>
                            <th style={{ width: "200px" }}>Description</th>
                            <th style={{ width: '100px' }}>Unit Price(N)</th>
                            <th style={{ width: '150px' }}>Qty</th>
                            <th style={{ width: '10px' }}>Discount(%)</th>
                            <th style={{ width: 150 }}>Discount</th>
                            <th style={{ width: 100 }}>VAT</th>
                            <th>Total Price(N)</th>
                            <th>
                              <div className="btn btn-sm printbtninv" onClick={() => addRow()}>
                                <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: "nowrap" }}>

                          {formData.map((row, index) => (
                            <tr key={index}>
                              <td style={{ width: "200px" }}>
                                <div style={{ width: 150 }}>
                                  <Select
                                    value={row.items}
                                    onChange={(selectedOption) => handleItemDescriptionChange(selectedOption, index)}
                                    options={selectOptions1}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '300px',
                                        overflowY: 'auto',
                                      }),
                                    }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: '150px' }}>
                                <textarea
                                  name="description"
                                  value={row.description}
                                  className="form-control"
                                  onChange={(e) => handleFormChange(e.target.value, "description", index)}
                                  style={{ border: "none", width: '100%' }}
                                  rows="1"
                                />

                              </td>
                              <td style={{ width: '50px' }}>
                                <input
                                  type="text"
                                  name={`rowUnitPrice ${index}`}
                                  decimalsLimit={2}
                                  value={row.unitPrice}
                                  className="form-control"
                                  onChange={(e) => handleFormChange(e.target.value, "unitPrice", index)}
                                  style={{ textAlign: "right", border: "none", width: '100%' }}
                                />

                              </td>
                              <td style={{ width: '100px' }}>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={row.qty}
                                  onChange={(e) => handleFormChange(e.target.value, "qty", index)}
                                  style={{ width: '100%' }}
                                />
                              </td>
                              <td style={{ width: '10px' }}>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={row.discountPercentage.includes('%') ? row.discountPercentage : `${row.discountPercentage}%`}
                                  onChange={(e) => handleFormChange(e.target.value, "discountPercentage", index)}
                                  onBlur={(e) => {
                                    const percentage = parseFloat(e.target.value.replace('%', '')) || 0;
                                    handleFormChange(percentage + '%', "discountPercentage", index);
                                  }}
                                  onFocus={(e) => {
                                    handleFormChange(row.discountPercentage.replace('%', ''), "discountPercentage", index);
                                  }}
                                  style={{ width: '80px' }}
                                />
                              </td>
                              <td style={{ width: '100px' }}>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={row.discount}
                                  onChange={(e) => handleFormChange(e.target.value, "discount", index)}

                                  style={{ textAlign: "right", border: "none", width: '100%' }}
                                />
                              </td>
                              <td style={{ width: "100px" }}>
                                <div style={{ width: 100 }}>
                                  <Select
                                    onChange={(selectedOption) => handleFormChange(selectedOption, "vat", index)}
                                    value={row.vat}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '300px',
                                        overflowY: 'auto',
                                      }),
                                    }}
                                    loading={loading} options={extendedTaxes} placeholder='Select VAT'
                                  />
                                </div>
                              </td>

                              <td style={{ width: '7rem' }}>
                                <CurrencyInput
                                  name={`rowLineTotal ${index}`}
                                  decimalsLimit={2}
                                  value={row.amount}
                                  className="form-control"
                                  disabled
                                  style={{ textAlign: "right", border: "none", width: '8rem' }}
                                />
                              </td>
                              <td style={{ textAlign: "center", width: "2rem" }}>
                                <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow(index)}>
                                  <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                )} */}

                {/* {selectedOption.service && ( */}
                  <div className="row" style={{ maxWidth: "100%", }}>
                    <h5 style={{ textAlign: "left" }}>Add Service(s)</h5>
                    {/* <div className="col-md-6"> */}
                    <div className="table-responsive">
                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">
                        <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                          <tr>
                            <th style={{ width: "200px" }}>Debit Account</th>
                            <th style={{ width: "200px" }}>Credit Account</th>
                            <th style={{ width: "200px" }}>Description</th>
                            <th style={{ width: "200px" }}>Amount</th>
                            <th style={{ width: '10px' }}>Discount(%)</th>
                            <th style={{ width: 150 }}>Discount</th>
                            <th style={{ width: 100 }}>VAT</th>
                            <th>Total Amount(N)</th>
                            <th>
                              <div className="btn btn-sm printbtninv" onClick={() => addRow1()}>
                                <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: "nowrap" }}>

                          {formData1.map((row, index) => (
                            <tr key={index}>
                              <td style={{ width: "170px" }}>
                                <div style={{ width: 170 }}>
                                <Select
                  value={row.services2}
                  onChange={(selectedOption) => handleItemDescriptionChange2(selectedOption, index)}
                  options={incs}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      // maxWidth: '200px',
                      overflowY: 'auto',

                    }),
                  }}
                />

                                </div>
                              </td>
                              <td style={{ width: "170px" }}>
                                <div style={{ width: 170 }}>
                                  <Select
                                    value={row.services}
                                    onChange={(selectedOption) => handleItemDescriptionChange1(selectedOption, index)}
                                    options={receivables}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '300px',
                                        // maxWidth: '200px',
                                        overflowY: 'auto',

                                      }),
                                    }}
                                  />

                                </div>
                              </td>
                              <td style={{ width: '170px' }}>
                                <textarea
                                  name="description"
                                  value={row.description}
                                  className="form-control"
                                  onChange={(e) => handleFormChange1(e.target.value, "description", index)}
                                  style={{ border: "none", width: '100%' }}
                                  rows="1"
                                />
                              </td>
                              <td style={{ width: '100px' }}>
                                <input
                                  name="amount"
                                  value={row.amount}
                                  className="form-control"
                                  onChange={(e) => handleFormChange1(e.target.value, "amount", index)}
                                  style={{ border: "none", width: '100%', textAlign: "right" }}

                                />

                              </td>

                              <td style={{ width: '10px' }}>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={row.discountPercentage.includes('%') ? row.discountPercentage : `${row.discountPercentage}%`}
                                  onChange={(e) => handleFormChange1(e.target.value, "discountPercentage", index)}
                                  onBlur={(e) => {
                                    const percentage = parseFloat(e.target.value.replace('%', '')) || 0;
                                    handleFormChange1(percentage + '%', "discountPercentage", index);
                                  }}
                                  onFocus={(e) => {
                                    handleFormChange1(row.discountPercentage.replace('%', ''), "discountPercentage", index);
                                  }}
                                  style={{ width: '100%' }}
                                />
                              </td>
                              <td style={{ width: '100px' }}>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={row.discount}
                                  onChange={(e) => handleFormChange1(e.target.value, "discount", index)}

                                  style={{ textAlign: "right", border: "none", width: '7rem' }}
                                />
                              </td>
                              <td style={{ width: "100px" }}>
                                <div style={{ width: 100 }}>
                                  <Select
                                    value={row.vat}
                                    onChange={(selectedOption) => handleFormChange1(selectedOption, "vat", index)}
                                    // options={taxes}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '300px',
                                        overflowY: 'auto',
                                      }),
                                    }}
                                    loading={loading} options={extendedTaxes} placeholder='Select VAT'
                                  />
                                </div>
                              </td>

                              <td style={{ width: '100px' }}>
                                <CurrencyInput
                                  name={`rowLineTotal ${index}`}
                                  decimalsLimit={2}
                                  value={row.totalAmount}
                                  className="form-control"
                                  onChange={(e) => handleFormChange1(e.target.value, "totalAmount", index)}
                                  disabled
                                  style={{ textAlign: "right", border: "none", width: '100%' }}
                                />
                              </td>
                              <td style={{ textAlign: "center", width: "2rem" }}>
                                <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow1(index)}>
                                  <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                {/* )}  */}
              </div>

              {/* {selectedOption.product && (
                <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15rem" }} >
                  <div className="col-md-6" style={{ marginLeft: '10px' }}>
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="description"
                          style={{ width: "1000px" }}
                        />

                      </div>
                    </Form.Group>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: 'flex-end', }}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                      <label for="example-text-input" style={{ textAlign: 'right' }}>Total VAT:</label>
                      <div style={{ padding: '0', width: '10rem', }}>
                        <CurrencyInput
                          name='total-amount'
                          decimalsLimit={2}
                          value={totalTax}
                          className="form-control"
                          disabled
                          style={{ textAlign: "right", border: "none", width: '10rem' }}
                        />

                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                      <label for="example-text-input" >Total Discount:</label>
                      <div style={{ padding: '0' }}>
                        <CurrencyInput
                          name='total-amount'
                          decimalsLimit={2}
                          value={totalDiscount}
                          className="form-control"
                          disabled
                          style={{ textAlign: "right", border: "none", width: '10rem' }}
                        />

                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: '5px', alignItems: 'center', }}>
                      <label for="example-text-input" >Total Amount:</label>
                      <div style={{ padding: '0' }}>
                        <CurrencyInput
                          name='total-amount'
                          decimalsLimit={2}
                          value={totalAmount}
                          className="form-control"
                          disabled
                          style={{ textAlign: "right", border: "none", width: '10rem' }}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {/* {selectedOption.service && ( */}
                <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15rem" }} >
                  <div className="col-md-6" style={{ marginLeft: '10px' }}>
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          // value={description}
                          // onChange={(e) => setDescription(e.target.value)}
                          name="description"
                          style={{ width: "1000px" }}
                        />

                      </div>
                    </Form.Group>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: 'flex-end', }}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                      <label for="example-text-input" style={{ textAlign: 'right' }}>Total VAT:</label>
                      <div style={{ padding: '0', width: '10rem', }}>

                        {/* <Select
                      value={row.items} 
                      onChange={(selectedOption) => handleItemDescriptionChange(selectedOption, index)}
                      options={selectOptions1}
                      menuPortalTarget={document.body}
                      styles={{

                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({
                          ...provided,
                          maxHeight: '100px',
                          overflowY: 'auto',

                        }),

                      }}
                    /> */}
                        <CurrencyInput
                          name='total-amount'
                          decimalsLimit={2}
                          value={totalTax1}
                          className="form-control"
                          disabled
                          style={{ textAlign: "right", border: "none", width: '10rem' }}
                        />

                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                      <label for="example-text-input" >Total Discount:</label>
                      <div style={{ padding: '0' }}>
                        <CurrencyInput
                          name='total-amount'
                          decimalsLimit={2}
                          value={totalDiscount1}
                          className="form-control"
                          disabled
                          style={{ textAlign: "right", border: "none", width: '10rem' }}
                        />

                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: '5px', alignItems: 'center', }}>
                      <label for="example-text-input" >Total Amount:</label>
                      <div style={{ padding: '0' }}>
                        <CurrencyInput
                          name='total-amount'
                          decimalsLimit={2}
                          value={totalAmount1}
                          className="form-control"
                          disabled
                          style={{ textAlign: "right", border: "none", width: '10rem' }}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              {/* )}  */}

              <div style={{ marginTop: 50 }} className={classes.bttn}>
                <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
                <Button style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {createLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button disabled style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {/* {createLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Send to Mail"
                          )} */}
                  Send to Mail
                </Button>
                <Button disabled style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {/* {createLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Print in PDF"
                          )} */}
                  Print in PDF
                </Button>
              </div>
            </div>









          </div>
        </div>

      </div>






    </div >
  )
}