import classes from './setupchart.module.css'
import Fullbullets from './Fullfeatures.svg';
import stuprcs from './hrtakant.svg';
import Backarrow from './Arrwbck.svg';
import { ProgressBar } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Editimg from './Editimg.svg';
import Deleteimg from './Deleteimg.svg';
import Addimg from './Addimg.svg';


const initialState = () => {
    return [{
        accountName: '', accountCode: '', category: '', direction: '', openingBalance: '', date: ''
    }]
}

const Chartofaounts = ({onBack}) => {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const [benBank, setBenBank] = useState([]);
    const [formData, setFormData] = useState([{accountName: '', accountCode: '', category: '', direction: '', openingBalance: '', date: ''}]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectOptions1, setSelectOptions1] = useState([]);
    const [selectOptions2, setSelectOptions2] = useState([]);
    const [totalAmount, setTotalAmount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadd, setLoadd] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [user, setUser] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [userTypes, setUserTypes] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [continent, setContinent] = useState('');
    const [admin, setAdmin] = useState('');
    const [category, setSubCategory] = useState('');
    const [accountName, setGLName] = useState('');
    const [direction, setDirection] = useState('');
    const [date, setTransactionDate] = useState('');
    const [openingBalance, setOpeningBal] = useState('');


    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            const value2 = await AsyncStorage.getItem('admin');
            const admins = await AsyncStorage.getItem('admin');

            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (value2 !== null) {
                setAdmin(value2);
            }

            if (admins !== null) {
                setUserTypes(admins);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);


    const uploadExcel = async () => {
        setUploadLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', selectedFile[0]);

            // console.log(selectedFile);

            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${bearer}`,
            };

            const response = await axios.post(
                'https://api-sme.promixaccounting.com/api/v1/payment_voucher/post-journal',
                formData,
                { headers }
            );
            handleClose();
            navigate('/pending_payment_voucher');
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });

            console.log(response.data.message);
        } catch (error) {
            const errorStatus = error.response.data.message;

            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorStatus,
            });

            console.error(error);
        } finally {
            setUploadLoading(false);
        }
    };

    const fetchData1 = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/get-sub-categories`, { headers });
          const subCat = response.data?.data;
          setTableData3(subCat);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
          }
        } finally {
          setIsLoading(false);
        }
      };

      useEffect(() => {
        if (bearer) {
          fetchData1();
        }
      }, [bearer]);

    

    


    const handlePosting = async () => {
        setLoadd(true);
        try {

            const accountNames = formData.map((row) => row.accountName).filter((name) => name !== undefined);
      const directions = formData.map((row) => row.direction).filter((name) => name !== undefined);
      const dates = formData.map((row) => row.date).filter((name) => name !== undefined);
      const categories = formData.map((row) => row.category).filter((name) => name !== undefined);
      const balances = formData.map((row) => parseFloat(row.openingBalance)).filter((balance) => !isNaN(balance));
    //   const balances = formData.map((row) => row.openingBalance).filter((name) => name !== undefined);
            
            const response = await axios.post(
                `${BASE_URL}/account/add-multiple`,
                {
                    sub_category_id: categories,
                    gl_name: accountNames,
                    direction: directions,
                    transaction_date: dates,
                    opening_balance: balances
                },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearer}`
                }
             }
            );

            console.log(response.data);
            setSubCategory('');
            setGLName('');
            setDirection('');
            setTransactionDate('');
            setOpeningBal('');
            
            if (response.data && response.data.message) {
                toast.success(response.data.message);
            } else {
                toast.success('Data saved successfully');
            }            
            
            // toast.success(response.data.message);
            // console.log(response.data);           

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });

        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
              }
              Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: JSON.stringify(error.response.data.message),
            });
            //   toast.error(errorMessage);
              console.log(error);
            }
        } finally {
            setLoadd(false);
        }
    };

    // const handleGLNameChange = (value, index) => {
    // let updatedGLName = [...glname];
    // updatedGLName[index] = value;
    // setGLName(updatedGLName);
    // };

    // const handleSubCatChangeee = (value, index) => {
    // let updateSubCat = [...subcategory];
    // updateSubCat[index] = value;
    // setSubCategory(updateSubCat);
    // };

    // const handleDirectionChange = (value, index) => {
    // let updateDirection = [...direction];
    // updateDirection[index] = value;
    // setSubCategory(updateDirection);
    // };

    // const handleTransactionChange = (value, index) => {
    // let updateTransaction = [...transactiondate];
    // updateTransaction[index] = value;
    // setTransactionDate(updateTransaction);
    // };

    // const handleOpeningChange = (value, index) => {
    // let updateOpening = [...openingbal];
    // updateOpening[index] = value;
    // setOpeningBal(updateOpening);
    // };

    const fetchBenAcct = async (selectedBeneficiary) => {
        setLoading(true);

        try {
            const response = await axios.get(
                `${BASE_URL}/beneficiaryaccounts/getaccount?beneficiary_id=${selectedBeneficiary}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${bearer}`,
                    },
                }
            );

            const paid = response.data?.data || [];
            const benAcc = paid.map((item) => ({
                label: item.bank_name,
                value: item.id,
            }));
            console.log(paid, 'paid');
            setBenBank(benAcc);
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };


    const handleFormChange = (index, field, value) => {
        let formattedValue = value;
    
        // Format the amount field
        if (field === 'openingBalance' && value !== '') {
          formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
        }        
    
        const updatedFormData = [...formData];
        updatedFormData[index][field] = formattedValue;
        setFormData(updatedFormData);
      };


    const deleteRow = (index) => {
        const updatedData = formData.filter((_, i) => i !== index);
        setFormData(updatedData);
    };





    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const fetchBeneficiaries = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
            const results = response.data?.data;

            const options = results.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            setTableData(results);
            setSelectOptions(options);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDebit = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/account`, { headers });
            const debitAcct = response.data?.data;

            const options1 = debitAcct.map((item) => ({
                label: item.gl_name,
                value: item.id,
            }));

            setTableData1(debitAcct);
            setSelectOptions1(options1);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData1([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAsset = async () => {
        setLoading(true);

        try {
            const response = await axios.get(
                `${BASE_URL}/accounts`,
                {

                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );
            const assetsAcc = response.data?.data;
            const options2 = assetsAcc.map((item) => ({
                label: item.gl_name,
                value: item.id,
            }));

            setTableData2(assetsAcc);
            setSelectOptions2(options2);


        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };




    useEffect(() => {
        if (bearer) {
            fetchBeneficiaries();
            fetchDebit();
            fetchAsset();

        }
    }, [bearer]);




    const addRow = () => {
        const newRow = {
           accountName: '', accountCode: '', category: '', direction: '', openingBalance: '', date: ''
        };

        setFormData([...formData, newRow]);
    };

   

    const calculateTotalAmount = () => {
        const total = formData.reduce((total, row) => total + parseFloat(row.amount) || 0, 0);
        setTotalAmount(total);
        // console.log(totalQuantity);
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [formData]);


    const testButon = () => {
        setLoadd(true);
    }

    return (
        <div className={classes.maincont1234}>
            <div className={classes.formSection}>
                <div className={classes.normdfalp}>
                    <div className={classes.formSection2345}>
                        <h4>Set up Chart of Accounts</h4>
                        <p className={classes.dtxt}>Add your chart of account</p>
                        {/* <img src={TopImage} alt='icon' className={classes.Imageshow213} /> */}
                        <div className="table-responsive">
                            <table className="table display table-striped table-hover bg-white m-0 card-table">

                                <thead className={classes.tableheadbackgroundcolor}>
                                    <tr>
                                        <th style={{ backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>S/N</th>
                                        <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Account Code</th>
                                        <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Account Name</th>
                                        <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Category</th>
                                        {/* <th style={{ width: '18%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Direction</th> */}
                                        {/* <th style={{ width: '22%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Opening Balance</th> */}
                                        {/* <th style={{ width: '10%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Balance as at</th> */}
                                        <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF" }}><div onClick={() => addRow()}>
                                            <img src={Addimg} alt='icon' className={classes.Imageshowdfsf213} />
                                        </div></th>

                                        {/* <th style={{ backgroundColor: "#164B2E", color: "#FFFFFF" }}><div onClick={() => addRow()}>
                                            <img src={Addimg} alt='icon' className={classes.Imageshowdfsf213} />
                                        </div></th> */}
                                    </tr>
                                </thead>
                                <tbody style={{ whiteSpace: "nowrap" }}>
                                    {formData.map((row, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                               <td >
                                                <Form.Control 
                                                style={{ width: "200px", textAlign: 'left',  }}
                                                type="text" 
                                                placeholder="Enter account code"
                                                value={row.accountCode}
                                                onChange={(e) => handleFormChange(index, 'accountCode', e.target.value)}                                                />
                                              
                                            </td>
                                            <td >
                                                <Form.Control type="text" 
                                                style={{ width: "200px", textAlign: 'left', }}
                                                placeholder="Enter account name" 
                                                value={row.accountName}
                                                onChange={(e) => handleFormChange(index, 'accountName', e.target.value)}                                                />
                                            </td>
                                         
                                            <td >
                                            <Form.Select className="form-control"
                                                as="select"
                                                style={{width: 300}}
                                                value={row.category}
                                                onChange={(e) => handleFormChange(index, 'category', e.target.value)}
                                                >
                                                <option value="" disabled>Select Type</option>
                                                {tableData3.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                    {item.description}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            </td>
                                            {/* <td>
                                                <Form.Select className="form-control"
                                                value={row.direction}
                                                onChange={(e) => handleFormChange(index, 'direction', e.target.value)}
                                                >
                                                    <option value="" disabled>Select Direction</option>
                                                    <option value="1" >Debit</option>
                                                    <option value="2" >Credit</option>
                                                </Form.Select>                                                
                                            </td> */}
                                            {/* <td style={{ width: "30px", textAlign: 'left', fontSize: "12px" }}>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter opening balance" 
                                                value={row.openingBalance}
                                                onChange={(e) => handleFormChange(index, 'openingBalance', e.target.value)}                                                />
                                            </td> */}
                                            {/* <td style={{ width: "10px", textAlign: 'left', fontSize: "12px" }}>
                                                <Form.Control type="date" 
                                                style={{ width: "50px"}}
                                                value={row.date}
                                                onChange={(e) => handleFormChange(index, 'date', e.target.value)}                                                />
                                            </td> */}
                                            <td style={{ textAlign: "left" }}> 
                                                <div onClick={() => deleteRow(index)}>
                                                    <img src={Deleteimg} alt='icon' className={classes.Imageshowdfsf213} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                        <div style={{marginTop: 20, cursor: "pointer"}}>
  <p onClick={onBack}>Go back</p>
</div>
                        
                        <Button variant="success" className={classes.snupbtndfa} onClick={handlePosting}>
                            {loadd ? (
                            <>
                          <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                                </>
                            ) : (
                                "Save and Continue"
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    )
};


export default Chartofaounts;