import React, { useState, useEffect } from 'react';
import classes from './AccountInfo.module.css';
// import SideImg from '../../assets/TeamMemberImg/TeamMemberSideImg.svg';
// import TopImage from './TopImage.svg';
// import Bullets from './Bullets.svg';
// import Profilessss from './Profile.svg';
// import Fullbullets from './addteam.svg';
// import Backarrow from './Arrwbck.svg';
import { Button, ProgressBar, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../../api/api';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export default function AccountInfo({ onNext, onPrevious }) {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [toggleStates, setToggleStates] = useState({});
    const [permId, setPermId] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const navigate = useNavigate();

    

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setUser(value1);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const fetchPermission = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/role/permissions`, { headers });
            const data = response.data?.data;
            const permissionId = data.map(item => item.id);
            setPermId(permissionId);
            setPermissions(data);
            // Initialize toggleStates with fetched permissions
            const initialToggleStates = Object.fromEntries(permissionId.map(id => [id, false]));
            setToggleStates(initialToggleStates);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.error(errorStatus);
            setPermissions([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (bearer) {
            fetchPermission();
        }
    }, [bearer]);

    const groupPermissions = (permissions, itemsPerRow) => {
        let groupedPermissions = [];
        for (let i = 0; i < permissions.length; i += itemsPerRow) {
            groupedPermissions.push(permissions.slice(i, i + itemsPerRow));
        }
        return groupedPermissions;
    };

    const groupedPermissions = groupPermissions(permissions, 5);

   

    const handleSelectAll = () => {
        const checkAllValue = !selectAll;
        setSelectAll(checkAllValue);

        // Set all toggle states to the determined value
        const updatedToggleStates = Object.fromEntries(permId.map(id => [id, checkAllValue]));
        setToggleStates(updatedToggleStates);
    };

    const handleIndividualSelect = (itemId) => {
        setToggleStates(prevToggleStates => {
            const newToggleState = { ...prevToggleStates, [itemId]: !prevToggleStates[itemId] };
            const allSelected = Object.values(newToggleState).every(value => value);
            setSelectAll(allSelected);
            return newToggleState;
        });
    };

    const addTeam = async () => {
        setAddLoading(true);
        try {
          const selectedToggle = permissions
          .filter((item) => toggleStates[item.id]) // Filters items where `toggleStates[item.id]` is true
          .map((item) => item.id);
      
            console.log(fullName, email, phone, selectedToggle);
      
          const response = await axios.post(
            `${BASE_URL}/users/create-with-module`,
            {
              name: fullName,
              email: email,
              phone_no: phone,
              module: selectedToggle
            },
            { headers }
          );
          console.log(response)
          
        //   navigate(-1);
          setFullName('');
          setPhone('');
          setEmail('');
          setToggleStates({});
        
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
        });
      
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: JSON.stringify(error.response.data.data),
            });
            //   toast.error(errorMessage);
              console.log(error);
            }
        } finally {
          setAddLoading(false);
        }
      };

    return (
      <div className={classes.maincont1234}>
        <div className={classes.formSection}>
          <div className={classes.normdfalp}>
            <div className={classes.formSection2345}>
              <h4 style={{ color: "#6C757D", fontSize: "18px" }}>
                Add your Team members seamlessly
              </h4>
              <p style={{ color: "#6C757D", fontSize: "12px" }}>
                Add Team members
              </p>
              <div className={classes.formSon2345}>
                <Form>
                  {/* <div className="row"> */}
                  <div className="col-md-10" style={{ marginLeft: 50 }}>
                    <Form.Group
                      className="mb-1"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-10" style={{ marginLeft: 50 }}>
                    <Form.Group className="mb-1">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  {/* </div> */}
                  <div className="col-md-10" style={{ marginLeft: 50 }}>
                    <Form.Group className="mb-1">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <Form.Label style={{ marginLeft: 50, marginTop: 20 }}>
                    Assign Modules to member
                  </Form.Label>
                  <div className={classes.checkmarg}>
                    <Form.Check
                      type="checkbox"
                      id="select-all"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="mb-3"
                      label="Select all"
                    />
                    {/* <Form.Check.Input type="checkbox" isValid />
                                        <Form.Check.Label>Select All</Form.Check.Label> */}
                    {/* </Form.Check> */}

                    {isLoading ? (
                        <Spinner animation="border" variant="success" />
                    ) : (
                      groupedPermissions.map((group, index) => (
                        <div
                          key={index}
                          className="mb-3 d-flex flex-wrap gap-3"
                        >
                          {group.map((item, subIndex) => (
                            <Form.Check
                              key={subIndex}
                              type="checkbox"
                              id={`check-api-${item.name}-${subIndex}`}
                              className="permission-item"
                              checked={toggleStates[item.id] || false}
                              onChange={() => handleIndividualSelect(item.id)}
                              label={item.name}
                            />
                          ))}
                        </div>
                      ))
                    )}

                    {/* //     <Form.Check.Input type="checkbox" isValid />
                                                //     <Form.Check.Label>{item.name}</Form.Check.Label>
                                                // </Form.Check> */}
                    {/* {groupedPermissions.map((group, index) => (
                                        <div key={index} className="mb-3 d-flex flex-wrap gap-3">
                                            {group.map((item, subIndex) => (
                                                <Form.Check
                                                    key={subIndex}
                                                    type="checkbox"
                                                    id={`check-api-${item.name}-${subIndex}`}
                                                    className="permission-item"
                                                    checked={toggleStates[item.id]}
                                                    onChange={() => handleIndividualSelect(item.id)}
                                                >
                                                    <Form.Check.Input type="checkbox" isValid />
                                                    <Form.Check.Label>{item.name}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                        </div>
                                    ))} */}
                  </div>
                </Form>

                <div className={classes.bottomF}>
                  <Button
                    variant="success"
                    className={classes.snupbtn}
                    onClick={addTeam}
                  >
                    {addLoading ? (
                      <>
                        <Spinner size="sm" />
                        {/* <span style={{ marginLeft: '5px' }}>Adding team member, Please wait...</span> */}
                      </>
                    ) : (
                      "Add Team Member"
                    )}
                  </Button>

                  <p onClick={onPrevious} style={{ marginTop: "1rem", cursor: "pointer" }} className={classes.closeBtn}>
                    Go Back
                  </p>
                  {/* <p
                    style={{ marginTop: "1rem" }}
                    className={classes.closeBtn1}
                  >
                    Skip
                  </p> */}
                </div>
              </div>
              <Button
                variant="success"
                className={classes.snupbtn1}
                onClick={onNext}
              >
                {/* {addLoading ? (
                                <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Adding team member, Please wait...</span>
                                </>
                                ) : ( */}
                Next
                {/* )} */}
              </Button>
              {/* <Link to={'/welcome'} ><Button variant="danger" className={classes.snupbtnreddfa} >
                            Skip
                        </Button></Link> */}
            </div>
          </div>
        </div>
      </div>
    );
}
