import React, { useState, useEffect } from 'react';
import classes from './Return.module.css';
import "../../../assets/datatables/dataTables.bootstrap4.min.css";
import { Spinner, Badge, Button, Modal, Form, Accordion, FormControl } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../../Pages/api/api';
import LatestInventoryDash from '../LatestInventoryDash'; import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input-field';

const initialState = () => {
  const unitPrice = '';
  const qty = '';
  return [{ items: '', unitPrice, qty, totalPrice: unitPrice * qty }];
};

export default function ReturnInward() {
  const [user, setUser] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedDoc, setSelectedDoc] = useState('');
  const [roleLoading, setRoleLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [debitGl, setDebitGl] = useState('');
  const [glMethod, setGlMethod] = useState([]);
  const [tableData23, setTableData23] = useState([]);
  const [sICode, setSICode] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');
  const [quantity12, setQuantity12] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [debitCode, setDebitCode] = useState('');
  const [roless, setRoless] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [debitAmount, setDebitAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
  const [formData, setFormData] = useState(initialState);
  const [outstanding, setOutstanding] = useState('');
  const [totalCharge, setTotalCharge] = useState("");
  const [itemList, setItemList] = useState([]);
  const [amountsToBePaid, setAmountsToBePaid] = useState({});
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectedInv, setSelectedInv] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [amount, setAmount] = useState('');
  const [tellerNumber, setTellerNumber] = useState("")
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("")
  const [paymentMode, setPaymentMode] = useState([]);
  const [amountToPay, setAmountToPay] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [passedUuid, setPassedUuid] = useState('');
  const [newData, setNewData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [glname, setGlname] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [glcode, setGlcode] = useState("");


  const handleSubCatChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleAmountChange = (value, itemId) => {
    setAmountsToBePaid((prev) => ({
      ...prev,
      [itemId]: value,
    }));

    const totalAmount = Object.values({
      ...amountsToBePaid,
      [itemId]: value,
    }).reduce((acc, current) => acc + parseFloat(current || 0), 0);


    setAmountToPay(totalAmount);
  };

  const handleSalesChange = (selectedOption) => {
    const selectedId = selectedOption.value;

    setSelectedInvoice(selectedOption.value);
    setPassedUuid(selectedOption.value21)

    const intselectedId = parseInt(selectedId);
    const selectedInvoice = newData.find(item => item.id == intselectedId);
    setDescription(selectedInvoice?.description || '');
    setAmount(selectedInvoice?.amount || '');
    setOutstanding(selectedInvoice?.balance || '');
    setSelectedInv(Array.isArray(selectedInvoice?.items) ? selectedInvoice?.items : [selectedInvoice?.items]);
    setSelectedDoc(selectedInvoice?.supporting_document || '');
    // console.log(selectedInvoice?.supporting_document);

  };

  const formattedOutstanding = isNaN(parseFloat(outstanding)) ? '0.00' : parseFloat(outstanding).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAmount = isNaN(parseFloat(amount)) ? '0.00' : parseFloat(amount).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const handleValueChange = (value, name, values) => {
    setAmountToPay(value);

  };

  const handleAccountChange = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedDebitAccount(selectedOption.value);
    }
  }


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const handleGlChange = (event) => {
    setDebitGl(event.target.value);
  }


  const handleCustomerChange = (event) => {
    const selectedCustomerId = event.target.value;
    setSelectedCustomer(selectedCustomerId);

    const selectedCus = customerList.find((item) => item.id === parseInt(selectedCustomerId));
    setAddress(selectedCus ? selectedCus.address : "");
  };

  const createAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,

        },
        { headers }
      );
      console.log(response)
      handleClose();
      fetchAcctName();
      setGlcode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData11 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-sub-categories`, { headers });
      const subCat = response.data?.data;
      setTableData23(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData23([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const calculatedTotalAmount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount(calculatedTotalAmount.toFixed(2))
  }, [formData]);


  useEffect(() => {
    const calculatedDebit = parseFloat(debitAmount || 0)
    setTotalDebit(calculatedDebit.toFixed(2))
  }, [debitAmount]);



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };





  const fetchGlMethod = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setGlMethod(resultss);

      // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/customer/no-pagination`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const custome = response.data?.data;
      setCustomerList(custome);
      // setAddress(custome);

      // console.log(custome, "itss");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchGlMethod();
      fetchCustomers();
      fetchData11();
    }
  }, [bearer]);




  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);




  const goBack = () => {
    navigate(-1);
  }








  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const options12 = accountName.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));



  const handleFormChange = (value, fieldName, rowIndex) => {
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      updatedFormData[rowIndex] = {
        ...updatedFormData[rowIndex],
        [fieldName]: value
      };
      updatedFormData[rowIndex].amount = parseFloat(updatedFormData[rowIndex].unitPrice) * parseFloat(updatedFormData[rowIndex].qty) || 0;
      return updatedFormData;
    });
  };




  const fetchAcctName = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-cash-and-bank`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAccountName(resultss);

      // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };



  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-paid-sales-invoice`, { headers });
      const roleList = response.data?.data;
      setNewData(roleList);
      // console.log(roleList);
      const roled = roleList.map((item) => ({
        label: `${item.invoice_number} - ${item.customer?.name}`,
        value: item.id,
        value21: item.invoice_number,
      }));

      setRoless(roled);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData4 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/income/get-payment-method`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData5(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData5([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData3 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData2([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAcctName();
      fetchItems();
      fetchData1();
      fetchData4();
      fetchData3();
    }
  }, [bearer]);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const createReturnInward = async () => {
    setPaymentLoading(true);

    try {
      // const formDatas = new FormData();
      // const descriptions = formData.map((row) => row.description).filter((id) => id !== undefined);
      // const quantities = formData.map((row) => row.qty).filter((id) => id !== undefined);
      // const amounts = formData.map((row) => row.amount).filter((id) => id !== undefined);
      // const taxx = formData.map((row) => row.vat.value).filter((id) => id !== undefined);


      // quantities.forEach((quantity, index) => {
      //   formDatas.append(`quantity[${index}]`, quantity);
      // });

      // amounts.forEach((amount, index) => {
      //   formDatas.append(`amount[${index}]`, amount);
      // });

      // formDatas.append('transaction_date', selectedDate);
      // formDatas.append('uuid', passedUuid);
      // formDatas.append('description', description);
      // formDatas.append('amount', formattedAmount);



      const filteredItems = selectedInv.filter(
        (item) => amountsToBePaid[item.id] && parseFloat(amountsToBePaid[item.id]) > 0
      );

      const itemIds = filteredItems.map((item) => item.id);
      const itemAmounts = filteredItems.map((item) => parseFloat(amountsToBePaid[item.id]));

      const itemID = selectedInv.map((row) => row?.item?.id); // Array of item names
      const quantities = selectedInv.map((_, index) => 
        index === 0 ? quantity12 : 0 // Adjust logic as needed to match your use case
      );
      const amounts = selectedInv.map((row) =>
        parseFloat(row?.amount).toFixed(2) // Ensure amounts are correctly formatted
      );

      let requestData = {
        uuid: passedUuid,
        transaction_date: selectedDate,
        reason: reason,
        description: description,
        item_id: itemID,
        quantity: quantities,
        amount: amounts || '',
      };



      console.log(selectedInvoice, requestData);
      // return;
      const response = await axios.post(
        `${BASE_URL}/return-goods-inward`,
        requestData,
        { headers }
      );

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      fetchData1();

      setSelectedInvoice('');
      setAmountToPay('');
      setSelectedDebitAccount('');
      setSelectedDate('');
      setDescription('');
      setAmount('');
      setTotalAmount('');
      setTellerNumber('');
      setOutstanding('');
      setSelectedPaymentMode('');

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };

  const extendedBank = [
    { label: '(Add a new bank account)', value: 'add-new' },
    ...options12
  ];

  const handleValueChange1 = (value, name, values) => {
    setBalance(value); // Update the balance state
    console.log(value, name, values);
  };


  return (
    <div>
      <ToastContainer />
      <LatestInventoryDash />

      <div className={classes.finishedbodyCont}>


        {/* <h3 className={classes.productText}>Customers' Receipt</h3> */}
        <h3 style={{ fontSize: 30, fontWeight: 700, }}>Return Inward</h3>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            {/* <div className={classes.formLabel}> */}
            {/* </div> */}

            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Create Bank Account</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form >
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Gl Name"
                      value={glname}
                      onChange={(e) => setGlname(e.target.value)}
                    />

                    <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      value={selectedSubCategory}
                      onChange={handleSubCatChange}
                    >
                      <option value="" disabled>Select Type</option>
                      {tableData23.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                    <input
                      className="form-control"
                      required=""
                      type="date"
                      onChange={handleDateChange}
                      name="date"
                      value={selectedDate}
                    />


                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createAccount}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                    </>
                  ) : (
                    "Create"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>


            <div className="d-flex justify-content-center">
              <Form style={{ width: '100%' }}>
                <div className={`row `} >
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom} style={{ textAlign: "right" }}>Transaction Date</Form.Label>
                        {/* <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} /> */}
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleDateChange}
                          name="date"
                          value={selectedDate}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Invoice Number</Form.Label>
                        <Select
                          className={classes.formControl12}
                          options={roless}
                          placeholder="Select Invoice"
                          onChange={handleSalesChange}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',

                            }),
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>

                </div>
                <div className={`row `} style={{ marginTop: -10 }}>

                  <div className="col-md-6" >
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          style={{ width: "97%" }}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom} style={{ textAlign: "right" }}>Total Amount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Total Amount"
                          disabled
                          value={formattedAmount}
                          onChange={(e) => setAmount(e.target.value)}
                          style={{ width: "97%" }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <h3 style={{ fontSize: 25, fontWeight: 700, marginTop: 30 }}>Item Details</h3>
                <div className={classes.mainTable}>
                  <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">
                    <thead style={{ whiteSpace: "nowrap", }}>
                      <tr>
                        <th style={{ width: "200px" }}>Item</th>
                        <th>Quantity</th>
                        <th>Return Quantity</th>
                        <th>Amount</th>
                        {/* <th>Amount to Pay(N)</th> */}
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: "nowrap" }}>

                      {selectedInv?.map((row, index) => (
                        <tr key={index}>
                          <td style={{ width: "150px", whiteSpace: 'wrap', height: 0, padding: 2 }}>
                          <input
                              type="text"
                                value={row?.item?.name}
                                className="form-control"
                                onChange={(e) => handleFormChange(e.target.value, "unitPrice", index)}
                                disabled
                                style={{ textAlign: "left", border: "none", width: '100%' }}
                              />
                          </td>
                          <td style={{ width: "150px", whiteSpace: 'wrap', height: 0, padding: 2 }}>
                          <input
                              type="text"
                                value={row?.item?.quantity}
                                className="form-control"
                                onChange={(e) => handleFormChange(e.target.value, "unitPrice", index)}
                                disabled
                                style={{ textAlign: "left", border: "none", width: '100%' }}
                              />
                          </td>
                          <td style={{ width: "150px", whiteSpace: 'wrap', height: 0, padding: 2 }}>
                          <input
                              type="text"
                                value={quantity12}
                                className="form-control"
                                onChange={(e) => setQuantity12(e.target.value)}
                                // disabled
                                style={{ textAlign: "left", border: "none", width: '100%' }}
                              />
                          </td>
                          <td style={{ textAlign: "right", width: "150px", padding: 2 }}>
                              <input
                              type="text"
                                value={parseFloat(row?.amount).toLocaleString('en-US', {
                                  minimumIntegerDigits: 1,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                                className="form-control"
                                onChange={(e) => handleFormChange(e.target.value, "unitPrice", index)}
                                disabled
                                style={{ textAlign: "right", border: "none", width: '100%' }}
                              />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="col-md-6" style={{ display: "flex", width: "100%", marginTop: 20 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom} style={{ textAlign: "left" }}>Reason</Form.Label>
                        <FormControl
                          //   
                          name="amount-to-pay"
                          as="textarea"
                          className="form-control"
                          value={reason} // Set the value to the balance state
                          onChange={(e) => setReason(e.target.value)}
                          style={{ textAlign: "left", border: "1px solid #e4e4e4", backgroundColor: "none", width: '80%' }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className={classes.buttonsGroup}>


                    <div className={classes.bttn}>
                      <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
                      <Button variant="success" onClick={createReturnInward}>
                        {paymentLoading ? (
                          <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>



              </Form>
            </div>



          </div>
        </div>





      </div>






    </div >
  )
}