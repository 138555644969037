import React, { useState } from "react";
import classes from "./Onboarding.module.css";
import Background from "../../assets/welc/newban.png";
import { Link, NavLink } from "react-router-dom";
import {
  Tab,
  Tabs,
  Button,
  Card,
  Form,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import ChooseAccountType from "./ChooseAccountType/ChooseAccountType.js";
// import AcountNews from "./AccountNew/AccountNews";
import UploadBalancessssss from "./Upload Initial Blalances/UploadBal.js";
import AccountInfo from "./AccountInfo/Accountinfo.js";
import AddTeam from "../AccountingNew/AddTeam.js";
import UploadAccounts from "../AccountingNew/UploadAccounts.js";
import AccIntroduction from "../AccountingNew/AccIntroduction.js";
import ChartofAccount from "../AccountingNew/setupchart.js";
import SetupChart from "../AccountingNew/SetupChartAcct.js";
import ChartManual from "../AccountingNew/ChartManual.js";
import UploadBalanceU from "./Upload Initial Blalances/UploadBal.js";
import UploadBalCSV from "../AccountingNew/UploadBalCSV.js";
import AccInfo from "./Account Info/AccInfo.js";
import autoTable from "jspdf-autotable";

const Payment = () => {
  const [key, setKey] = useState("home");
  const [currentStep, setCurrentStep] = useState(0);
  const [step, setStep] = useState("introduction");
  const [hasChart, setHasChart] = useState(null);
  const currentYear = new Date().getFullYear();
  const [fetchedAccounts, setFetchedAccounts] = useState(null);
  const [fetchedBalances, setFetchedBalances] = useState(null);
  const [initialBalanceStep, setInitialBalanceStep] = useState("csv");


  const handleNextBalance = (data) => {
    setInitialBalanceStep("uploadU");
    setFetchedBalances(data);
  };

  const handleBackBalance = () => {
    setInitialBalanceStep("csv");
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 2)); // Increment step
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0)); // Decrement step
  };

  const handleIntroductionResponse = (response) => {
    setHasChart(response); // Save yes/no response
    setStep(response ? "upload" : "setup"); // Move to the next step based on response
  };

  const handleNextAccount = (data) => {
    if (step === "upload") {
      setStep("chartOfAccount"); // Move to Chart of Account after upload
    } else if (step === "setup") {
      setStep("manual");
      setFetchedAccounts(data); // Move to Manual Chart after setup
    }
  };

  const clearAccounts = () => {
    if (step === 'manual') {
      setStep('setup');
    }
};

  
  const handlePrevAcct = () => {
    if (step === "upload") {
      setStep("introduction");
    }
  };
  const handlePrevAcct2 = () => {
    if (step === 'setup') {
      setStep('introduction');
    }
  };
  const handlePrevAcct3 = () => {
    if (step === 'manual') {
      setStep('setup');
    }
  };

  const handlePrevAcct1 = () => {
    if (step === "chartOfAccount") {
      setStep("upload");
    }
  };

  return (
    <div class={classes.header}>
      <img src={Background} class={classes.pic} alt="Onboarding" />

      <div className="content">
        <div className={classes.card}>
          <div className={classes.cardmain}>
            {/* <div>
              <p
                style={{
                  fontSize: 14,
                  color: "#6C757D",
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 30,
                  cursor: "pointer",
                }}
              >
                Skip
              </p>
            </div> */}
            <AccInfo />
            {/* <Tabs
              id="controlled-tab-example"
              defaultActiveKey="paymentInfo"
              className="mb-3 complete-tabs"
              variant="underline"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                minWidth:"80%",
                textAlign:'center',
                gap:10,
                marginLeft:"auto",
                marginRight:'auto'
              }}
              fill
            >
              <Tab
                eventKey="paymentInfo"
                title="Payment Plan"
                style={{ width: "100%" }}
              >
                
              </Tab>
           

             

              
            </Tabs> */}
          </div>
      <div
        class={classes.footeer}
        style={{ textAlign: "center", padding: "20px 0 20px 0" }}
      >
        <p>&copy; {currentYear} Brookes Professional Services Limited.</p>
      </div>
        </div>
      </div>

    </div>
  );
};

export default Payment;
