import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './StockMovement.module.css'
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import { BASE_URL } from '../../api/api';
import LatestInventoryDash from '../LatestInventoryDash'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


function StockMovement() {
  const [searchTerm, setSearchTerm] = useState("");
  // const [totalPages, setTotalPages] = useState("");
  // const [totalEntries, setTotalEntries] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [suppliedPrice, setSuppliedPrice] = useState([]);
  const [transQty, setTransQty] = useState([]);
  const [quantity12, setQuantity12] = useState([]);
  const [quantity1223, setQuantity1223] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [glAccount, setGlAccount] = useState([]);
  const [productName2, setproductName2] = useState([]);
  const [formData, setFormData] = useState([{ productName: '', quantity: '', suppliedPrice: '', transQty: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [transLoading, setTransLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [glopening, setGlopening] = useState([]);
  const [glclosing, setGlclosing] = useState([]);
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedStore2, setSelectedStore2] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const handleSubStore = (selectedOption) => {
    setSelectedStore(selectedOption.value);
  };

  const handleSubStore2 = (selectedOption) => {
    setSelectedStore2(selectedOption.value);
  };

  const fetchPaidBookings = async () => {
    
    setLedgerLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/reports/general-ledger`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const genLed = response.data?.data?.ledgers;
      // const ledgr = response.data?.data?.accounts;
      setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      console.log(response, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLedgerLoading(false);
    }
  };

  // useEffect(() => {
  //   if (bearer) {
  //     fetchPaidBookings();

  //   }
  // }, [bearer]);

  const fetchAccounts = async () => {
    setFetchingLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-store-products`, {
        params: {
          id: selectedStore,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const resultsss = response.data?.data;
      setGlAccount(resultsss);

      const options1 = resultsss.map((item) => ({
        label: item?.item?.name,
        value: item.id,
        value2: item?.item?.id,
        quantity: item.quantity,
        cost_price: item?.item?.cost_price,
      }));
      setGlAccount(options1);

      console.log(options1);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setFetchingLoading(false);
    }
  };


  const moveStock = async () => {
    setTransLoading(true);

    try {
        const pName = formData.map((row) => row.productName?.value).filter((id) => id !== undefined);
        // const quantity = formData.map((row) => row.quantity).filter((name) => name !== undefined);
        const price = formData.map((row) => row.suppliedPrice).filter((name) => name !== undefined);
        const transQty = formData.map((row) => row.transQty).filter((name) => name !== undefined);
        // const amount1 = incomeAmount.replace(/,/g, '').replace(/\.00$/, '');

        
// return;
    // console.log("params:", selectedAccount, selectedDate, selectedCustomer);

        const response = await axios.post(`${BASE_URL}/transfer-product`,
            {
                id: pName,
                from: selectedStore,
                type: selectedAccount,
                store_id: selectedStore2,
                quantity_supplied: transQty,
                supplied_price: price,
            },
            { headers }
        );

        console.log(response.data, "heeee");
        setFormData([{ productName: '', quantity: '', suppliedPrice: '', transQty: '' }]);
        navigate(-1);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              // toast.error(errorMessage)
              console.log(errorMessage);
            }
            // setFormData([{ productName: '', quantity: '', suppliedPrice: '', transQty: '' }]);
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
        }
    } finally {
        setTransLoading(false);
    }
};

  const fetchCharts = async () => {
    setIsLoading(true);
    // console.log(selectedAccount, "year")
    try {
      const response = await axios.get(`${BASE_URL}/fetch-store-types`, { 
        params: {
          type: selectedAccount,
        },
        headers });

      const results = response.data?.data[0];
      setTableData4(results);

      const results12 = response.data?.data[1];
      setTableData45(results12);
      console.log(results12);

      // const opt112 = results.map((item) => ({
      //   label: item.store_name,
      //   value: item.id,
      // }));

      // const opt12 = results12.map((item) => ({
      //   label: item.store_name,
      //   value: item.id,
      // }));
      // setTableData45(opt12);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData4([]);
      setTableData45([]);
    } finally {
      setIsLoading(false);
    }
  };

  const options1 = tableData4.map((item) => ({

    label: item.store_name,
    value: item.id,
  }));
  
  const options2 = tableData45.map((item) => ({
    label: item.store_name,
    value: item.id,
  }));


  const fetchStoreType = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/order-types`, { headers });
      const results = response.data?.data;
      console.log(results);

      const opt1 = results.map((item) => ({
        label: item,
        value: item,
      }));
      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedAccount) {
      fetchCharts(selectedAccount);

    }
  }, [bearer, selectedAccount]);

  useEffect(() => {
    if (bearer && selectedStore) {
      fetchAccounts(selectedStore);

    }
  }, [bearer, selectedStore]);

  useEffect(() => {
    if (bearer) {
      fetchStoreType();

    }
  }, [bearer]);



  useEffect(() => {
    if (GenLedger) {
      const debitTotal = GenLedger.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = GenLedger.reduce((total, item) => total + parseFloat(item.credit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [GenLedger]);



 

 
  // const filteredData = GenLedger?.filter(item => item?.details.toLowerCase().includes(searchTerm.toLowerCase()));

  const filteredData = GenLedger.filter((item) => {
    // Convert all fields to lowercase to make the search case-insensitive
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
    // Check if the search term is present in any of the fields
    return (
      item.transaction_date.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.details.toLowerCase().includes(lowerCaseSearchTerm) ||
      formatDate(item.created_at).toLowerCase().includes(lowerCaseSearchTerm) ||
      item.second.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.debit.toString().includes(lowerCaseSearchTerm) ||
      item.credit.toString().includes(lowerCaseSearchTerm) ||
      item.balance.toString().includes(lowerCaseSearchTerm)
    );
  });  
  
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

 
  


  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const handleCopyToClipboard = () => {
    // Define headers
    const headers = ['S/N', 'Post Date', 'Value Date', 'Description', 'Debit', 'Credit'];
  
    // Convert headers array to a string with tab-separated values
    let tableString = headers.join('\t') + '\n';
  
    // Convert table data to a string with tab-separated values
    GenLedger.forEach((item, index) => {
      const row = [
        index + 1,
        formatDate(item.created_at),
        item.transaction_date,
        item.details,
        parseFloat(item.debit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        parseFloat(item.credit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ];
      tableString += row.join('\t') + '\n';
    });
  
    // Copy the string to the clipboard
    navigator.clipboard.writeText(tableString).then(() => {
      alert('General Ledger result copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
  
  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
      { header: 'S/N', key: 'index', width: 10 },
      { header: 'Post Date', key: 'post_date', width: 60 },
      { header: 'Value Date', key: 'value_date', width: 60 },
      { header: 'Description', key: 'description', width: 100 },
      { header: 'Debit', key: 'debit', width: 70 },
      { header: 'Credit', key: 'credit', width: 70 }
    ];

    // Add data to worksheet
    GenLedger.forEach((item, index) => {
      worksheet.addRow({
       index:  index + 1,
        post_date: formatDate(item.created_at),
        value_date: item.transaction_date,
       descriptions:  item.details,
       debit: parseFloat(item.debit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        credit: parseFloat(item.credit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      });
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'General Ledger.xlsx');
  };

  const addRow = () => {
    const newRow = {
      productName: '', quantity: '', suppliedPrice: '', transQty: ''
    };
    setFormData([...formData, newRow]);
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };



  const handleAccountChange1223 = (index, selectedOption) => {
    // `selectedOption` is the selected option object from `react-select`
    const selectedAccount = selectedOption?.value; // The selected account ID
    const quantityRate = parseInt(glAccount.find((item) => item.value === selectedAccount)?.quantity) || 0;
    const suppliedPrice = parseInt(glAccount.find((item) => item.value === selectedAccount)?.cost_price) || 0;

    
      // Create a copy of formData and update the specific row
  const updatedFormData = [...formData];
  updatedFormData[index] = {
    ...updatedFormData[index],
    productName: selectedOption, // Save the entire selected option for compatibility with `react-select`
    quantity: quantityRate,
    suppliedPrice: suppliedPrice,
    transQty: updatedFormData[index]?.transQty || 0, // Retain the current transQty value
  };

  setFormData(updatedFormData);

  };
  

  


  const handleFormChange = (value, field, index) => {
    // Ensure the value is parsed correctly for numeric fields
    const parsedValue = field === "transQty" ? parseInt(value) || '' : value;
  
    // Create a copy of formData and update the specific field for the current row
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [field]: parsedValue,
    };
  
    setFormData(updatedFormData); // Update state
  };

  const handlePdfExport = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
  
    // Set the font size and style
    pdf.setFontSize(10);
  
    // Define the starting point for the content
    let startY = 10;
  
    // Add a title to the document
    pdf.text('General Ledger', 10, startY);
  
    // Add some space after the title
    startY += 10;
  
    // Define the table headers
    const headers = ['S/N', 'Post Date', 'Value Date', 'Description', 'Debit', 'Credit'];
  
    // Convert your data to the format required by jsPDF for autoTable
    const data = GenLedger.map((item, index) => [
      index + 1,
      formatDate(item.created_at),
      item.transaction_date,
      item.details,
      parseFloat(item.debit).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      parseFloat(item.credit).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    ]);
  
    // Add the table to the PDF using autoTable plugin
    pdf.autoTable({
      startY: startY,
      head: [headers],
      body: data,
      theme: 'striped',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [22, 160, 133] }, // Customize the header color (optional)
    });
  
    // Save the PDF with the name "Users List.pdf"
    pdf.save('General Ledger.pdf');
  };

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestInventoryDash />
                  {/* <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                        <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''}
</p>
                            <h3>General Ledgers</h3>
                            <small>Create and view your loan GenLedger...</small>
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                            <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

                        </div>
                    </div> */}
                </div>

                <div className={classes.finishedbodyCont}>
                <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  22px" }}>Stock Movement</h3>
                <p style={{margin:" 0 10px 20px 22px" }}>Process stock movement by selecting the below required field</p>
                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>General Ledger</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}`}>
                        <div className="card-body" style={{ border: 'none' }}>


                          <div className="row">
                            <div className={`row ${classes.awrow}`} style={{ marginTop: 10, }}>
                              <div className="col-md-4">
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-sm-5 col-form-label font-weight-400">Movement Type:</label>
                                  <div className={`col-sm-9  ${classes.lblrow}`}>
                                    <Select
                                      onChange={(selectedOption) => handleAccountChange(selectedOption)}
                                      options={tableData}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 `} style={{marginLeft: -100}}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-sm-5 col-form-label font-weight-400">From:</label>
                                  <div className={`col-sm-9  ${classes.lblrow}`}>
                                    <Select
                                      onChange={(selectedOption) => handleSubStore(selectedOption)}
                                      options={options1}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.marginLeftalign1}`}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-sm-5 col-form-label font-weight-400">To:</label>
                                  <div className={`col-sm-9  ${classes.lblrow}`}>
                                    <Select
                                      onChange={(selectedOption) => handleSubStore2(selectedOption)}
                                      options={options2}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                        <div className={`modal-footer ${classes.overlayBtnrows11}`}>
                        <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to transfer stock between selected stores</Tooltip>}
                              >
                                <Button
                                style={{ width: 100, height: "30px", padding: "0", borderRadius: 5 }}
                                disabled={!selectedAccount || !selectedStore || !selectedStore2}
                                  className={classes.overlayBtns11} variant='success' onClick={moveStock}>
                                  {transLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
                              </OverlayTrigger>

                            </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                     
                    </div>















                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="m-0 content-header row align-items-center">
                        <div className="p-0 col-sm-8 header-title">
                          <div className="media">
                            {/* <div className="mr-3 header-icon text-success"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="mb-4 col-md-12 col-lg-12 col-xl-3">
                    <div className="card">


                    </div>
                  </div> */}





                          <div className="col-lg-12">
                            <div className="card">
                              {/* <div style={{ marginLeft: 10 }}>
                                {GenLedger.length > 0 && (
                                  <button onClick={() => navigate('/accounting/general_ledger', { state: { document: GenLedger, inputs: inputss, glname: glAccount, glopening, glclosing } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10, }}>Print Report</button>
                                )}
                              </div> */}

                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '0px 0 0 0', marginBottom: 0 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`} >
                                      <div className={classes.actionsBtns}>
                                      <button disabled={GenLedger.length === 0} onClick={handleCopyToClipboard}>Copy</button>
                                        <button disabled={GenLedger.length === 0} onClick={handleExcelExport}>Excel</button>
                                        <button disabled={GenLedger.length === 0} onClick={handlePdfExport}>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div>
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                  {ledgerLoading || fetchingLoading ? (
                                    <p>Fetching data...</p>
                                  ) : (
                                    <div className="table-responsive" id="table-to-pdf">
                                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                          <tr>
                                            <th>S/N</th>
                                            <th>Product Name</th>
                                            <th>Quantity</th>
                                            <th>Supplied Price</th>
                                            <th>Transfer Quantity</th>
                                            {/* <th>Contra GL</th>
                                            <th>Debit</th>
                                            <th>Credit</th> */}
                                            <th>
                                            <div className="btn btn-sm printbtninv" onClick={() => addRow()}>
                                                <i className="fas fa-plus" style={{color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12}}></i>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                          {formData.length > 0 ? (
                                             <>
                                            {formData.map((row, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td style={{ width: "170px" }}>
                                                  <div style={{width: 170}}>
                                                  <Select
                                                    value={row.productName}
                                                    onChange={(selectedOption) => handleAccountChange1223(index, selectedOption)}
                                                    options={glAccount}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                      menu: (provided) => ({
                                                        ...provided,
                                                        maxHeight: '300px',
                                                        // maxWidth: '200px',
                                                        overflowY: 'auto',

                                                      }),
                                                    }}
                                                  />
                                                  
                                                  </div>
                                                </td>                                  
                                                <td>
                                                <input
                                                  type="text"
                                                    value={row.quantity}
                                                    className="form-control"
                                                    onChange={(e) => setQuantity12(e.target.value, "quantity", index)}
                                                    disabled
                                                    style={{ textAlign: "left", border: "none", width: '100%' }}
                                                  />
                                                </td>
                                                <td>
                                                <CurrencyInput
                                                  type="text"
                                                    value={row.suppliedPrice}
                                                    decimalsLimit={2}
                                                    className="form-control"
                                                    onChange={(e) => setSuppliedPrice(e.target.value, "suppliedPrice", index)}
                                                    disabled
                                                    style={{ textAlign: "right", border: "none", width: '100%' }}
                                                  />
                                                </td>
                                                <td style={{width: 200, whiteSpace: "wrap"}}>
                                                  <input
                                                  type="text"
                                                    value={row.transQty}
                                                    className="form-control"
                                                    onChange={(e) => handleFormChange(e.target.value, "transQty", index)}
                                                    // onChange={(e) => setTransQty(e.target.value)}
                                                    // disabled
                                                    style={{ textAlign: "left", border: "none", width: '100%' }}
                                                  />
                                              </td>
                                              <td style={{ textAlign: "center", width: "2rem" }}>
                                                <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow(index)}>
                                                  <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12}}></i>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                            </>
                                          ) : (
                                            <tr>
                                              <td colSpan="8" style={{ textAlign: 'center' }}>No stock available</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default StockMovement;