import React, { useState, useEffect } from 'react';
import classes from './AddTeamMember.module.css'
import SideImg from '../../assets/TeamMemberImg/TeamMemberSideImg.svg'
import TopImage from './TopImage.svg';
import Bullets from './Bullets.svg';
import Profilessss from './Profile.svg';
import Fullbullets from './addteam.svg';
import Backarrow from './Arrwbck.svg';
import {  ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';


export default function TeamMember1({ onNext, onPrevious }) {
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [Loading12, setSkipLoading12] = useState(false);
    const [admin, setAdmin] = useState("");
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [roleLoading, setRoleLoading] = useState(false);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('tobi');
          const value2 = await AsyncStorage.getItem('permissions');
          const value3 = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
          }
          if (value1 !== null) {
            setUser(value1);
          }
          if (value2 !== null) {
            setPermissions(value2);
          }
          if (value3 !== null) {
            setAdmin(value3);
          }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

      const fetchData = async () => {
        setRoleLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/users/fetch-all`, { headers });
          const results = response.data?.data;
          // const resultx = response.data?.data?.total;
          // setTotalEntries(resultx);
          setTableData(results);
          console.log(response);
          // const total = response.data?.data?.last_page || 1;
          // setTotalPages(total);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
          }
        } finally {
          setRoleLoading(false);
        }
      };

      useEffect(() => {
        if (bearer) {
          fetchData();
        }
      }, [bearer]);

      const handleSkip = async () => {
        setSkipLoading12(true);
    
        try {
            const response = await axios.post(
              `${BASE_URL}/authorize`,
              {},
              { headers }
            );
    
            console.log('User switched:', response);
    
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });
    
            navigate('/welcome');
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
    
            if (error.response?.data?.message) {
                const { message, data } = error.response.data;
    
                if (typeof message === 'string') {
                    errorMessage = message;
                } else if (Array.isArray(message)) {
                    errorMessage = message.join('; ');
                } else if (typeof message === 'object') {
                    errorMessage = JSON.stringify(message);
                }
    
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: JSON.stringify(data || errorMessage),
                });
            }
    
            toast.error(errorMessage);
            console.error('Error during user switch:', error);
        } finally {
            setSkipLoading12(false);
        }
    };
    
    

    return (
        <div className={classes.maincont1234}>
            <div className={classes.formSection}>
                <div className={classes.normdfalp}>
                    <div className={classes.formSection2345}>
                        {/* <img src={TopImage} alt='icon' className={classes.Imageshow213} /> */}
                        <h4>Add your Team members seamlessly</h4>
                        {/* <p>Add your Team members</p> */}
                            {roleLoading ? (
                                <Spinner size='sm' />
                            ) : (
                        <div className={classes.formSection23fa45}>
                            <img src={Profilessss} alt='icon' className={classes.Imageshowdfsf213} />
                            <div>
                                <h5>{tableData?.length} Members</h5>
                                <p>{tableData?.length === 0 ? `Add team members with the button below and they will have access to some of the modules assigned to them` : `Team members added, you can press below to add more or press the Next button to proceed`} </p>
                            </div>
                        </div>
                            )}
                            
                         <Button disabled={roleLoading} variant="success" className={classes.snupbtn} onClick={onPrevious}>
                            Add a Team member
                        </Button>

                      
                        <Button disabled={roleLoading} variant="success" className={classes.snupbtnreddfa} onClick={handleSkip}>
                        {Loading12 ? (
                                <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                </>
                                ) : (
                                "Next"
                                )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    )
}
