import React, { useEffect, useState } from "react";
import classes from "./Onboarding.module.css";
import Background from "../../assets/welc/newban.png";
import { Link, NavLink } from "react-router-dom";
import {
  Tab,
  Tabs,
  Button,
  Card,
  Form,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import ChooseAccountType from "./ChooseAccountType/ChooseAccountType";
// import AcountNews from "./AccountNew/AccountNews";
import UploadBalancessssss from "./Upload Initial Blalances/UploadBal";
import AccountInfo from "./AccountInfo/Accountinfo";
import AddTeam from "../../Pages/AccountingNew/AddTeam";
import UploadAccounts from "../../Pages/AccountingNew/UploadAccounts";
import AccIntroduction from "../../Pages/AccountingNew/AccIntroduction";
import ChartofAccount from "../../Pages/AccountingNew/setupchart";
import SetupChart from "../../Pages/AccountingNew/SetupChartAcct";
import ChartManual from "../../Pages/AccountingNew/ChartManual";
import UploadBalanceU from "./Upload Initial Blalances/UploadBal";
import UploadBalCSV from "../AccountingNew/UploadBalCSV";
import AccInfo from "./Account Info/AccInfo.js";
import autoTable from "jspdf-autotable";

const Onboarding = () => {
  const [key, setKey] = useState("home");
  const [activeTab, setActiveTab] = useState("chartsAccount");
  const [tabsDisabled, setTabsDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [step, setStep] = useState("introduction");
  const [hasChart, setHasChart] = useState(null);
  const currentYear = new Date().getFullYear();
  const [fetchedAccounts, setFetchedAccounts] = useState(null);
  const [fetchedBalances, setFetchedBalances] = useState(null);
  const [initialBalanceStep, setInitialBalanceStep] = useState("csv");

  const goToTab = (tabKey) => setActiveTab(tabKey);

  // Reset active tab when the page is navigated to
  useEffect(() => {
    setActiveTab("chartsAccount"); // Ensures chartsAccount is shown on navigation
  }, []);

  const handleCompleteChartManual = () => {
    setKey("initialBalances"); // Switch to Upload Initial Balances tab
    setInitialBalanceStep("csv"); // Show UploadBalCSV component
  };

  const handleNextBalance = (data) => {
    setInitialBalanceStep("uploadU");
    setFetchedBalances(data);
  };

  const handleBackBalance = () => {
    setInitialBalanceStep("csv");
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 2)); // Increment step
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0)); // Decrement step
  };

  const handleIntroductionResponse = (response) => {
    setHasChart(response); // Save yes/no response
    setStep(response ? "upload" : "setup"); // Move to the next step based on response
  };

  const handleNextAccount = (data) => {
    if (step === "upload") {
      setStep("chartOfAccount"); // Move to Chart of Account after upload
    } else if (step === "setup") {
      setStep("manual");
      setFetchedAccounts(data); // Move to Manual Chart after setup
    }
  };

  const clearAccounts = () => {
    if (step === 'manual') {
      setStep('setup');
    }
};

  
  const handlePrevAcct = () => {
    if (step === "upload") {
      setStep("introduction");
    }
  };
  const handlePrevAcct2 = () => {
    if (step === 'setup') {
      setStep('introduction');
    }
  };
  const handlePrevAcct3 = () => {
    if (step === 'manual') {
      setStep('setup');
    }
  };

  const handlePrevAcct1 = () => {
    if (step === "chartOfAccount") {
      setStep("upload");
    }
  };

  return (
    <div class={classes.header}>
      <img src={Background} class={classes.pic} alt="Onboarding" />

      <div className="content">
        <div className={classes.card}>
          <div className={classes.cardmain}>
            <div>
              <p
                style={{
                  fontSize: 14,
                  color: "#6C757D",
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 30,
                  cursor: "pointer",
                }}
              >
                Skip
              </p>
            </div>
            <Tabs
                          id="controlled-tab-example"
                          activeKey={activeTab} // Bind to state
                          onSelect={(k) => setActiveTab(k)}
                          className="mb-3 complete-tabs"
                          variant="underline"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                minWidth:"80%",
                textAlign:'center',
                gap:10,
                marginLeft:"auto",
                marginRight:'auto'
              }}
              // fill
            >
              <Tab
                eventKey="chartsAccount"
                title="Chart of Accounts"
                style={{ width: "100%" }}
                
              >
                {step === "introduction" && (
                  <AccIntroduction onResponse={handleIntroductionResponse} />
                )}
                {step === "upload" && (
                  <UploadAccounts
                    onNext={handleNextAccount}
                    onBack={handlePrevAcct} // Ensure this is a valid function
                  />
                )}
                {step === "chartOfAccount" && (
                  <ChartofAccount
                    onNext={handleNextAccount}
                    onBack={handlePrevAcct1}
                  /> // Pass the handler for moving to the next step
                )}
                {step === "setup" && (
                  <SetupChart
                    onNext={handleNextAccount}
                    onBack={handlePrevAcct2}
                  /> // Pass the handler for moving to the next step
                )}
                {step === "manual" && (
  <ChartManual 
    onBack={handlePrevAcct3} 
    accounts={fetchedAccounts}
    onGoToNextTab={() => goToTab("initialBalances")} // Handler to call after API call
  />
)}
              </Tab>

              <Tab  eventKey="initialBalances" title="Upload Initial Balances">
              {initialBalanceStep === "csv" && (
          <UploadBalCSV onNext={handleNextBalance} />
        )}

        {initialBalanceStep === "uploadU" && (
          <UploadBalanceU  onGoToNextTab={() => goToTab("teamMembers")} onBack={handleBackBalance} balance={fetchedBalances}/>
        )}

              </Tab>

              <Tab
              
                eventKey="teamMembers"
                title="Team Members"
                style={{ width: "100%" }}
              >
                {currentStep === 0 && <ChooseAccountType onNext={handleNext} />}
                {currentStep === 1 && (
                  <AccountInfo
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                  />
                )}
                {currentStep === 2 && <AddTeam onPrevious={handlePrevious} />}
              </Tab>
            </Tabs>
          </div>
      <div
        class={classes.footeer}
        style={{ textAlign: "center", padding: "20px 0 20px 0" }}
      >
        <p>&copy; {currentYear} Brookes Professional Services Limited.</p>
      </div>
        </div>
      </div>

    </div>
  );
};

export default Onboarding;
