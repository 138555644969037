import React, { useEffect, useState } from "react";
import classes from "./Registration.module.css";
import main from "./main.jpg";
import vector from "./Vector.jpg";
import gB from "./GreenBallon.jpg";
import ballon from "./Ballon.jpg";
import ballons from "./Ballons.jpg";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { BASE_URL } from "../../api/api";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";


const Registration = () => {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
   const [Loading12, setSkipLoading12] = useState(false);

   const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const value4 = await AsyncStorage.getItem('companyId');

      if (value !== null) {
        setBearer(value);
      }
     
   
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleSkip = async () => {
    setSkipLoading12(true);

    try {
        const response = await axios.post(
          `${BASE_URL}/authorize`,
          {},
          { headers }
        );

        console.log('User switched:', response);

        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
        });

        navigate('/welcome');
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';

        if (error.response?.data?.message) {
            const { message, data } = error.response.data;

            if (typeof message === 'string') {
                errorMessage = message;
            } else if (Array.isArray(message)) {
                errorMessage = message.join('; ');
            } else if (typeof message === 'object') {
                errorMessage = JSON.stringify(message);
            }

            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: JSON.stringify(data || errorMessage),
            });
        }

        toast.error(errorMessage);
        console.error('Error during user switch:', error);
    } finally {
        setSkipLoading12(false);
    }
};



    return (
          <div className={classes.layout}>
          <div class={classes.ballon} >
          <img src={ballons} class={classes.ballon3} alt="GreyBallon" />
          <img src={gB} class={classes.ballon1} alt="GreenBallon" />
          <img src={ballon} class={classes.ballon2} alt="GreyBallon" />
          </div>
          <div className={classes.main}>
          <img src={vector} class={classes.pic1} alt="curvedline" />
          <img src={main} class={classes.mainpic} alt="Registration" />
          <img src={vector} class={classes.pic3} alt="curvedline" />
        </div>

          <div className={classes.successText}>
          <h1 style={{color: '#343A40', textAlign: 'center', fontSize: 36, fontWeight: 700, }}>Registration Complete</h1>
          <p style={{ color: '#6C757D', textAlign: 'center', fontSize: 18,  fontWeight: 400 }}>
  Your Onboarding process is complete, please proceed to your <br /> 
  dashboard to manage your profile
</p>

          </div>
          <div className={classes.layouts}> 
          <Button
                variant="success"
                className={classes.snupbtn1}
                onClick={handleSkip}
              >
                
                {Loading12 ? (
                                               <>
                                                 <Spinner size='sm' />
                                                 {/* <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span> */}
                                               </>
                                             ) : (
                                               "Proceed to Dashboard"
                                             )}
              </Button>
          </div>
          
          </div>
        
    )
}

export default Registration;