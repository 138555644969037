import React from 'react';
import styles from './ChooseAccountType.module.css';
import user from "./userIcon.svg"
import { Link } from 'react-router-dom';

const TeamMembers = ({ onNext }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Add your Team members seamlessly</h1>
      <p className={styles.subheader}>Add as many team members as you want</p>
      <div className={styles.card}>
        <div className={styles.icon}><img src={user} alt="user-icon"/></div>
        <div>

        <p className={styles.addText}>No Team members to show here</p>
        <p className={styles.addLink}>Add team members with the button below 
        and they will show up here</p>
        </div>
      </div>
      <h4 onClick={onNext} className={styles.teamMember}>Add a Team member</h4>
      {/* <Link to={"/add_team_members"}> */}
      <button onClick={onNext} className={styles.button}>Next</button>
      {/* </Link> */}
    </div>
  );
};

export default TeamMembers;
