import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Modal,
  Form,
  Spinner,
  Badge,
} from "react-bootstrap";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Swal from "sweetalert2";
import classes from "./Services.module.css";
import { BASE_URL } from "../api/api";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import LatestInventoryDash from "../Inventory/LatestInventoryDash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import StockDashboard from "../Stock Dashboard/StockDashboard";
import LatestEventDash from "../LatestDashboard/LatestEventDash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Accordion } from "react-bootstrap";

const initializeFormData = selectedWIP => {
  if (selectedWIP?.expenses && selectedWIP.expenses.length > 0) {
    return selectedWIP.expenses.map(item => ({
      items: { value: item?.item?.id || "", label: item?.item?.name || "" },
      inventory: item?.item?.purchase_gl?.gl_name || "",
      goodCost: item?.item?.cost_of_good_gl?.gl_name || "",
      unitPrice: item.price || "",
      qty: item.quantity || "",
      totalPrice: item.amount || "",
    }));
  } else {
    return [
      {
        items: "",
        inventory: "",
        goodCost: "",
        unitPrice: "",
        qty: "",
        totalPrice: "",
      },
    ];
  }
};

function LabourCostInvent() {
  const location = useLocation();
  const { selectedWIP } = location.state || {};
  console.log(selectedWIP);
  const [user, setUser] = useState("");
  const [particulars, setParticulars] = useState(
    selectedWIP?.particulars || ""
  );
  const [subCat, setSubcat] = useState([]);
  const [subCat2, setSubcat2] = useState([]);
  const [subCat3, setSubcat3] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalCharge, setTotalCharge] = useState(selectedWIP?.amount || "");
  const [selectedaAsetAccount, setSelectedAssetAccount] = useState(
    selectedWIP?.asset_account || ""
  );
  const [description, setDescription] = useState(
    selectedWIP?.description || ""
  );

  const [title, setTitle] = useState(selectedWIP?.title || "");
  const [selectedAccount, setSelectedAccount] = useState(
    selectedWIP?.booking_account || ""
  );
  const [selectedService, setSelectedService] = useState(
    selectedWIP?.income_account || ""
  );
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(() =>
    initializeFormData(selectedWIP)
  );
  const [totalAmount, setTotalAmount] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    selectedWIP?.start_date || ""
  );

  const [selectedDate1, setSelectedDate1] = useState(
    selectedWIP?.end_date || ""
  );
  const startdate = selectedDate;
  const enddate = selectedDate1;
  const title3 = title;
  const [eventId, setEventId] = useState(selectedWIP?.booking_order || "");
  const [itemList, setItemList] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [bearer, setBearer] = useState("");

  const navigate = useNavigate();

  const handleDateChange = event => {
    setSelectedDate(event.target.value);
  };

  const handleEvent = event => {
    setEventId(event.target.value);
  };

  console.log(formData);
  const handleAssetChange = event => {
    setSelectedAssetAccount(event.target.value);
  };

  const handleAccountChange = event => {
    setSelectedAccount(event.target.value);
  };

  const handleServiceChange = event => {
    setSelectedService(event.target.value);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value2 = await AsyncStorage.getItem("companyId");
      const value1 = await AsyncStorage.getItem("tobi");

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      //   if (value2 !== null) {
      //     setCompanyId(value2);
      //   }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleValueChange = (value, name, values) => {
    setTotalCharge(value); // Update the balance state
  };

  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchRevenues = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const revenueResult = response.data?.data;
      setRevenues(revenueResult);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/raw-materials`, {
        headers,
      });
      const itemss = response.data?.data;

      const options1 = itemss.map(item => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
      console.log(options1, itemss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const options = results.map(chart => ({
        label: chart.gl_name,
        value: chart.id,
      }));

      setTableData(options);
      setSelectOptions(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();
    }
  }, [bearer]);

  const fetchSubCat2 = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-category-id?category_id=${1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      const optionsss = resultss.map(chart => ({
        label: chart.gl_name,
        value: chart.id,
      }));
      setSubcat2(optionsss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };
  const fetchSubCat3 = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-category-id?category_id=${7}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      const optionsss = resultss.map(chart => ({
        label: chart.gl_name,
        value: chart.id,
      }));
      setSubcat3(optionsss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchSubCat();
      fetchSubCat2();
      fetchSubCat3();
      fetchItems();
      fetchRevenues();
    }
  }, [bearer]);

  const updateWIP = async () => {
    setUpdateLoading(true);
    try {
      const quantities = formData
        .map(row => row.qty)
        .filter(id => id !== undefined);
      const unitPrices = formData
        .map(row => row.unitPrice)
        .filter(id => id !== undefined);
      const totalPrices = formData
        .map(row => row.totalPrice)
        .filter(id => id !== undefined);
      const stocks = formData
        .map(row => row.items.value)
        .filter(id => id !== undefined);
      const costs = formData
        .map(row => row.goodCost.value)
        .filter(id => id !== undefined);
      const inventories = formData
        .map(row => row.inventory.value)
        .filter(id => id !== undefined);
      console.log(
        quantities,
        unitPrices,
        totalPrices,
        stocks,
        "BEFORE SENDING"
      );

      const response = await axios.post(
        `${BASE_URL}/wif/save-material-cost`,
        {
          // inventory_gl: inventories,
          // cost_of_good_gl: costs,
          stock_id: stocks,
          quantity: quantities,
          price: unitPrices,
          total_amount: totalAmount,
          amount: totalPrices,
          wip_id: selectedWIP?.id,
        },
        { headers }
      );

      console.log(response.data.message);
      setParticulars("");
      setSelectedDate("");
      setSelectedDate("");
      setSelectedDate1("");
      setTotalCharge("");
      setSelectedAssetAccount("");
      setSelectedAccount("");
      setDescription("");
      navigate(-1);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: errorMessage,
        });
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };
  // const updateWIP = async () => {

  //   setUpdateLoading(true);
  //   try {
  //     const quantities = formData.map((row) => row.qty).filter((id) => id !== undefined);
  //     const unitPrices = formData.map((row) => row.unitPrice).filter((id) => id !== undefined);
  //     const totalPrices = formData.map((row) => row.totalPrice).filter((id) => id !== undefined);
  //     const stocks = formData.map((row) => row.items.value).filter((id) => id !== undefined);
  //   console.log(quantities, unitPrices, totalPrices, stocks, "BEFORE SENDING");

  //     const response = await axios.post(
  //       `${BASE_URL}/booking/add-expenses`,
  //       {
  //         particulars: particulars,
  //         event_date: selectedDate,
  //         start_hour: selectedTime,
  //         end_hour: selectedTime1,
  //         end_hour: selectedTime1,
  //         amount: totalCharge,
  //         asset_account: selectedaAsetAccount,
  //         booking_account: selectedAccount,
  //         description: description,
  //         product_id:stocks,
  //         quantity: quantities,
  //         unit_price: unitPrices,
  //         amounts: totalPrices,
  //         income_account: selectedService,
  //         booking_id: selectedWIP?.id

  //       },
  //       { headers }
  //     );
  //     toast.success(response.data.message);
  //     console.log(response.data.message)
  //     setParticulars("");
  //     setSelectedDate("");
  //     setSelectedTime("");
  //     setSelectedTime1("");
  //     setTotalCharge("");
  //     setSelectedAssetAccount("");
  //     setSelectedAccount("");
  //     setDescription("");
  //     navigate(-1);

  //   } catch (error) {
  //     let errorMessage = 'An error occurred. Please try again.';
  //     if (error.response && error.response.data && error.response.data.message) {
  //         if (typeof error.response.data.message === 'string') {
  //             errorMessage = error.response.data.message;
  //         } else if (Array.isArray(error.response.data.message)) {
  //             errorMessage = error.response.data.message.join('; ');
  //         } else if (typeof error.response.data.message === 'object') {
  //             errorMessage = JSON.stringify(error.response.data.message);
  //         }
  //         toast.error(errorMessage);
  //         console.log(error);
  //     }
  //   } finally {
  //     setUpdateLoading(false);
  //   }
  // };

  const addRow = () => {
    const newRow = {
      items: "",
      unitPrice: "",
      qty: "",
      totalPrice: "",
      items: "",
      inventory: "",
      goodCost: "",
    };
    setFormData([...formData, newRow]);
  };

  const deleteRow = index => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const handleItemDescriptionChange = async (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    // const selectedUnitPrice = selectedItem?.price || 0;
    const stockQuantity = selectedItem?.stock?.quantity || 0;
    // const inventoryGl = selectedItem?.purchase_gl?.gl_name || "";
    // const costOfGoodGl = selectedItem?.cost_of_good_gl?.gl_name || "";

    handleFormChange(selectedOption, "items", rowIndex);
    // handleFormChange(selectedUnitPrice, "unitPrice", rowIndex);
    handleFormChange(stockQuantity, "stockQuantity", rowIndex);
    // handleFormChange(inventoryGl, "inventory", rowIndex); // Update inventory GL field
    // handleFormChange(costOfGoodGl, "goodCost", rowIndex);
  };

  const handleFormChange = (value, fieldName, rowIndex) => {
    const updatedFormData = [...formData];
    updatedFormData[rowIndex][fieldName] = value;
    const quantity = parseFloat(updatedFormData[rowIndex].qty) || 0;
    const unitPrice = parseFloat(updatedFormData[rowIndex].unitPrice) || 0;
    updatedFormData[rowIndex].totalPrice = (quantity * unitPrice).toFixed(2);
    setFormData(updatedFormData);
    updateTotalCharge(updatedFormData);
  };

  const updateTotalCharge = updatedFormData => {
    const totalChargeValue = updatedFormData.reduce((acc, row) => {
      return acc + (parseFloat(row.totalPrice) || 0);
    }, 0);
    setTotalCharge(totalChargeValue.toFixed(2));
  };

  const calcTotalAmount = () => {
    const updatedFormData = formData.map(row => ({
      ...row,
      totalPrice: parseFloat(row.unitPrice) * parseFloat(row.qty) || 0,
    }));
    setFormData(updatedFormData);
  };

  // useEffect(() => {
  //   calcTotalAmount();
  // }, [formData]);

  const calcTotalAmount1 = () => {
    const total = formData.reduce(
      (total, row) => total + parseFloat(row.totalPrice) || 0,
      0
    );
    setTotalAmount(total);
  };

  useEffect(() => {
    calcTotalAmount1();
    // calcTotalAmount();
  }, [formData]);

  return (
    <div>
      <div className="wrapper">
        {/* <!-- Sidebar  --> */}

        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <LatestInventoryDash />
            <div className="newBody">
              <div className={classes.newWidthinvent}>
                <h3 className={classes.productText}>Work in Progress Detail</h3>
                <div className={classes.topPadding}>
                  <div className={`${classes.formSecCont}`}>
                    <div className="card-body" style={{ border: "none" }}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> Description </Accordion.Header>
                          <Accordion.Body>
                          {description} <br />
                            {/* <p>Title</p> */}
                            {/* <p><b>{title}</b></p> */}
                            <p style={{ marginTop: "10px", }}>Start Date <span><b>{selectedDate}</b></span></p>
                            <p style={{ marginTop: "5px", }}>End Date <span><b>{selectedDate1}</b></span></p>
                            {/* <Container>
                    <Row>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}> Title </Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              // placeholder="0.00"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className="align-items-left form-group-custom">
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>
                              Start Date
                            </Form.Label>
                            <div style={{ width: '100%' }}>
                            <Form.Control
                            disabled
                              type="date"
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(e.target.value)}
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className="align-items-left form-group-custom">
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>
                              End Date
                            </Form.Label>
                            <div style={{ width: '100%' }}>
                            <Form.Control
                            disabled
                              type="date"
                              value={selectedDate1}
                              onChange={(e) => setSelectedDate1(e.target.value)}
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container> */}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <div className="row">
                        <hr
                          style={{
                            marginTop: 20,
                            color: "#c4c4c4",
                            borderWidth: "0.5px",
                          }}
                        />

                        <div className="row">
                          <div style={{ marginTop: 20 }} />
                          <h5 style={{ textAlign: "center" }}>
                            Add WIP Labour Cost
                          </h5>
                          <div className="table-responsive">
                            <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">
                              <thead
                                style={{
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <tr>
                                  <th style={{}}>Description</th>
                                  <th>Amount</th>
                                  <th>Quantity</th>
                                  <th>Total Price(N)</th>
                                  <th>
                                    <Button
                                      variant="primary"
                                      onClick={() => addRow()}
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody style={{ whiteSpace: "nowrap" }}>
                                {formData.map((row, index) => (
                                  <tr key={index}>
                                    <td style={{ width: "300px" }}>
                                      <Select
                                        value={row.items}
                                        onChange={selectedOption =>
                                          handleItemDescriptionChange(
                                            selectedOption,
                                            index
                                          )
                                        }
                                        options={selectOptions1}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: base => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          menu: provided => ({
                                            ...provided,
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          }),
                                        }}
                                      />
                                    </td>
                                    <td style={{ width: "300px" }}>
                                    <CurrencyInput
                                        type="text"
                                        name={`rowUnitPrice ${index}`} // Provide a unique name for each CurrencyInput
                                        decimalsLimit={2}
                                        value={row.unitPrice}
                                        className="form-control"
                                        onChange={e =>
                                          handleFormChange(
                                            e.target.value,
                                            "unitPrice",
                                            index
                                          )
                                        }
                                        // disabled
                                        style={{
                                          textAlign: "right",
                                          border: "none",
                                          width: "100%",
                                        }}
                                      />
                                      {/* <input                                        
                                        type="text"
                                        name={`rowUnitPrice ${index}`}
                                        decimalsLimit={2}
                                        value={row.unitPrice}
                                        className="form-control"
                                        onChange={e =>
                                          handleFormChange(
                                            e.target.value,
                                            "unitPrice",
                                            index
                                          )
                                        }
                                        // disabled
                                        style={{
                                          textAlign: "right",
                                          border: "none",
                                          width: "100%",
                                        }}
                                      /> */}
                                    </td>
                                    <td style={{ width: "300px" }}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={row.qty}
                                        onChange={e =>
                                          handleFormChange(
                                            e.target.value,
                                            "qty",
                                            index
                                          )
                                        }
                                      />
                                      {row.quantityError && (
                                        <span style={{ color: "red" }}>
                                          {row.quantityError}
                                        </span>
                                      )}
                                    </td>
                                    <td style={{ width: "300px" }}>
                                      <CurrencyInput
                                        name={`rowLineTotal ${index}`} // Provide a unique name for each CurrencyInput
                                        decimalsLimit={2}
                                        value={row.totalPrice}
                                        className="form-control"
                                        disabled
                                        style={{
                                          textAlign: "right",
                                          border: "none",
                                          width: "100%",
                                        }}
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <Button
                                        variant="danger"
                                        onClick={() => deleteRow(index)}
                                      >
                                        <i className="far fa-trash-alt"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          className="col-md-11"
                          style={{ marginLeft: 45, marginTop: 30 }}
                        >
                          <div className="form-group row justify-content-end">
                            <label
                              for="example-text-input"
                              className="col-sm-2 col-form-label font-weight-400"
                            >
                              Total Amount:
                            </label>
                            <div
                              className="col-sm-4"
                              style={{ padding: "0", maxWidth: "18.5%" }}
                            >
                              <CurrencyInput
                                name="total-amount" // Provide a unique name for each CurrencyInput
                                decimalsLimit={2}
                                value={totalAmount}
                                className="form-control"
                                disabled
                                style={{
                                  textAlign: "right",
                                  border: "none",
                                  width: "10rem",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: 20 }} />

                      <div
                        className={`${classes.formIntBtn} ${classes.formIntBtn2}`}
                      >
                        <Button
                          variant="light"
                          className={classes.btn1}
                          onClick={goBack}
                        >
                          {" "}
                          Cancel
                        </Button>
                        <Button
                          style={{ borderRadius: 5, marginLeft: 10 }}
                          variant="success"
                          onClick={updateWIP}
                        >
                          {updateLoading ? (
                            <>
                              <Spinner size="sm" />
                              <span style={{ marginLeft: "5px" }}>
                                Updating your WIP in process, Please wait...
                              </span>
                            </>
                          ) : (
                            "Add WIP Labour Cost"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.main content--> */}
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}
      </div>
    </div>
  );
}

export default LabourCostInvent;
