import React, { useState, useEffect } from "react";
import classes from "./InventorySalesCash.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useLocation } from "react-router-dom";

export default function InventorySalesCash() {
  const [bearer, setBearer] = useState("");
  const [company, setCompany] = useState("");
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [totalDebit, setTotalDebit] = useState("");
  const [totalCredit, setTotalCredit] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [continent, setContinent] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [userType, setUserType] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [totalTotalAmount, setTotalTotalAmount] = useState(0);
  

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("companyName");
      const continentName = await AsyncStorage.getItem("continent");
      const regionName = await AsyncStorage.getItem("region");
      const provinceName = await AsyncStorage.getItem("province");
      const admins = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setCompany(value1);
      }

      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (admins !== null) {
        setUserType(admins);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const {
    accounts,
    inputss,
    glAccount,
    glopening,
    glclosing,
    selectedDate,
    selectedEndDate,
  } = location.state || {};
  console.log(accounts, inputss, glAccount, glopening, selectedDate);
  // const startDate = new Date(inputss.selectedDate);
  // const endDate = new Date(inputss.end_date);
  const accountNames = glAccount;
  const accountOpening = glopening;

  const groupedTransactions = accounts.reduce((acc, item) => {
    const key = item.transaction_number;
  
    if (!acc[key]) {
      acc[key] = []; 
    }
  
    acc[key].push({
      created_date:item.created_at,
      product_name: item.product.name,
      quantity: item.quantity,
      unit_price: parseFloat(item.product.price).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      total_amount: parseFloat(item.total_amount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    });
  
    return acc;
  }, {});
  
  // Calculate grand totals
let grandTotalUnitPrice = 0;
let grandTotalAmount = 0;

accounts.forEach((transaction) => {
  grandTotalUnitPrice += parseFloat(transaction.product.price);
  grandTotalAmount += parseFloat(transaction.total_amount);
});

// Format the results
grandTotalUnitPrice = grandTotalUnitPrice.toLocaleString("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

grandTotalAmount = grandTotalAmount.toLocaleString("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

// Output the results
console.log("Grand Total Unit Price:", grandTotalUnitPrice);
console.log("Grand Total Amount:", grandTotalAmount);

  // console.log(accountNames, "here");

  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return v >= 11 && v <= 13 ? "th" : suffixes[v % 10] || "th";
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.debit),
        0
      );
      const creditTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.credit),
        0
      );
      const balanceTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.balance),
        0
      );

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedCreditTotal = creditTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedBalanceTotal = balanceTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
      setTotalBalance(formattedBalanceTotal);
    }
  }, [accounts]);

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (accounts.length > 0) {
      const totalAmount = accounts.reduce(
        (sum, item) => sum + parseFloat(item.amount || 0),
        0
      );
      const totalVAT = accounts.reduce(
        (sum, item) => sum + parseFloat(item.vat || 0),
        0
      );
      const totalTotalAmount = accounts.reduce(
        (sum, item) => sum + parseFloat(item.total_amount || 0),
        0
      );

      // Format the totals
      setTotalAmount(totalAmount.toLocaleString("en-US", { minimumFractionDigits: 2 }));
      setTotalVAT(totalVAT.toLocaleString("en-US", { minimumFractionDigits: 2 }));
      setTotalTotalAmount(
        totalTotalAmount.toLocaleString("en-US", { minimumFractionDigits: 2 })
      );
    }
  }, [accounts]);

  return (
    <div className={classes.a4}>
      <div className={classes.report}>
        <h2 className={classes.headerTop}>{company}</h2>
        <h4 className={classes.headerTwoo}>{inputss?.name}</h4>
        <h4 className={classes.headerTwo}>
          CASHBOOK REPORT BETWEEN {selectedDate} AND {selectedEndDate}
        </h4>

       
          <table>
          <thead>
              <tr>
                <th style={{width:'20%'}}>Post Date</th>
                <th style={{width:'20%'}} >Product</th>
                <th style={{width:'20%'}}>Quantity</th>
                <th style={{textAlign:'right',width:'20%'}}>Unit Price</th>
                <th style={{textAlign:'right',width:'20%'}}>Total Amount</th>
              </tr>
            </thead>
        {Object.entries(groupedTransactions).map(([transactionNumber, products]) =>  (
          <>
            
          <h6 style={{fontWeight:'bold'}}>{transactionNumber}</h6>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td style={{width:'20%'}}>{formattedDates(product.created_date)}</td>
                  <td style={{width:'20%'}}>{product.product_name}</td>
                  <td style={{width:'20%'}}>{product.quantity}</td>
                  <td style={{textAlign:'right',width:'20%'}}>{product.unit_price}</td>
                  <td style={{textAlign:'right',width:'20%'}}>{product.total_amount}</td>
                </tr>
              ))}
            </tbody>
            </>
        ))}
              <tr style={{marginTop:20}}>
                <td style={{fontWeight:'bold'}}>Total Amounts</td>
                <td></td>
                <td></td>
                <td style={{textAlign:'right',fontWeight:'bold'}}>{grandTotalUnitPrice}</td>
                <td style={{textAlign:'right',fontWeight:'bold'}}>{grandTotalAmount}</td>
              </tr>
          </table>
      </div>
    </div>
  );
}
