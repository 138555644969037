import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link, NavLink } from 'react-router-dom';
import classes from './AccountNew.module.css';
import { Button, Container, ProgressBar } from 'react-bootstrap';
import CashDash from '../../Cash Dash/CashDash';
import Leftback from './LeftBack.svg';
import TopImage from './TopImage.svg';
import { Chart } from 'react-charts';
import header from "../header-bg.png";


const CashDashboard = () => {
   
    return (
        <>
        <div class={classes.header}>
          <img src={header} class={classes.pic} alt="header pic" />
        </div>
            {/* <div style={{ position: 'absolute', top:'0', left:'0', margin:'0 auto' }}>
          <h2  style={{padding: '2rem 5rem 16rem 8rem', color:'white' }}>
            Welcome to <br />Promix Accounting
          </h2>
            </div> */}

        <div className="content">
          <div className={classes.card}>
            <div className={classes.cardmain}>
              <Container
                fluid
                className="d-flex justify-content-center align-items-center vh-80"
                style={{ margin: "-105px 0 0 0" }}
              >
            <div className={classes.maincont1234}>

            <div className={classes.formSection}>
                    <div className={classes.formSection2345}>
                        <img src={TopImage} alt='icon' className={classes.Imageshow213} />
                        <h4>Registration Complete</h4>
                        <p>Your onboarding process is complete, please proceed to your
                        dashboard to manage your profile.</p>
                      <Link to={'/welcome'} >  <Button variant="success" className={classes.snupbtn} >
                        Proceed
                    </Button></Link>      
                    </div>
            </div>

        
</div>
              </Container>
               </div>
               </div>
               </div> 
            {/* <div className={classes.sideNavContainer}> */}
            {/* <img src={Leftback} alt='icon' className={classes.mobileshow} /> */}
            {/* <h4> Welcome to <br /> Promix Accounting </h4>
            <p> Proceed to your dashboard </p>
            </div> */}

            
            </>      

    );
}

export default CashDashboard;