import React, { useState, useEffect } from 'react';
import classes from './ManageStocks.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestInventoryDash from './LatestInventoryDash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Select from "react-select";



export default function ManageExpiredProduct() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [unit, setUnit] = useState("");
  const [unit1, setUnit1] = useState("");

  const [roless, setRoless] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [name1, setName1] = useState("");
  const [description1, setDescription1] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState("");
  const [price1, setPrice1] = useState("");

  const [glCode, setglCode] = useState("");
  const [stockBalance, setStockBalance] = useState("");
  const [stockBalance1, setStockBalance1] = useState("");
  const [reOderLevel, setReOrderLevel] = useState("");
  const [glCode1, setglCode1] = useState("");
  const [reOderLevel1, setReOrderLevel1] = useState("");

  const [tableData3, setTableData3] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [receivables, setReceivables] = useState([]);
  const [payables, setPayables] = useState([]);
  const [price12, setPrice12] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [selectedAdvance, setSelectedAdvance] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDisc, setSelectedDisc] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [costGood, setCostGood] = useState([]);
  const [tableData23, setTableData23] = useState([]);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [selectedType, setSelectedType] = useState(null);






  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/dashboard/get-expired-product`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  // const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));
  const filteredData = tableData.filter(item => item.type === "2");
  console.log(filteredData);
  
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {
    const foundItems = tableData.find(item => item.id === id);
    const itemId = foundItems.id;
    setSelectedItem(itemId);


    const { name, description, unit, gl_code, re_order_level, price, stock } = foundItems;

    setName1(name || '');
    setDescription1(description || '');
    setglCode1(gl_code || '');
    setReOrderLevel1(re_order_level || '');
    setUnit1(unit || '');
    setPrice1(price || '');
    setStockBalance1(stock?.quantity || '');

    setShow1(true);
    setEyeClicked(true);
  };

  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);
  const fetchData11 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-sub-categories`, { headers });
      const subCat = response.data?.data;
      setTableData23(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData23([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/items/update-item`,
        {
          name: name1,
          description: description1,
          unit: unit1,
          gl_code: glCode1,
          re_order_level: reOderLevel1,
          id: selectedItem,
          price: price1,
          quantity: stockBalance1
        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this stock.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/items/delete-item?id=${id}`, { headers });
      toast.success(response.data.message);
      fetchData();
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value)
  }
  const handleGlChange = (event) => {
    setglCode(event.target.value)
  }

  const handleUnitChange1 = (event) => {
    setUnit1(event.target.value)
  }
  const handleGlChange1 = (event) => {
    setglCode1(event.target.value)
  }

  const handleSingleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setSelectedImages(files);
  };

    const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/units/fetch-all`, { headers });
      const roleList = response.data?.data;
      // console.log(results);
      setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/product-categories`, { headers });
      const ts = response.data?.data;
      // console.log(results);
      setTableData3(ts);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData1();
      fetchData2();
      fetchGl();
      fetchData11();
    }
  }, [bearer]);



  const createUser = async () => {
    setCreateLoading(true);
    try {
      const formDatas = new FormData();
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      formDatas.append('name', name);
      formDatas.append('description', description);
      formDatas.append('unit', unit?.value || '');
      formDatas.append('gl_code', glCode);
      formDatas.append('type', '2');
      formDatas.append('re_order_level', reOderLevel || '');
      formDatas.append('price', removeFormatting(price) || '');
      formDatas.append('cost_price', removeFormatting(price12) || '');
      formDatas.append('quantity', stockBalance);
      formDatas.append('sales_gl', selectedSales || '');
      formDatas.append('payable_gl', selectedPayable || '');
      formDatas.append('purchase_gl', selectedPurchase || '');
      formDatas.append('account_receivable', selectedAccount || '');
      formDatas.append('category_id', selectedCategory?.value || '');
      formDatas.append('advance_payment_gl', selectedAdvance || '');
      formDatas.append('image', selectedImages);
      formDatas.append('display_image', selectedImage);
      formDatas.append('cost_of_good_gl', selectedCost || '');
      formDatas.append('discount_gl', selectedDisc || '');
      
      const response = await axios.post(
        `${BASE_URL}/items/add-new-item`,
       formDatas,
       {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${bearer}`
        }
      }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      setName('');
      setDescription('');
      setUnit('');
      setPrice('');
      setPrice12('');
      setSelectedCategory('');
      setSelectedSales('');
      setSelectedPurchase('');
      setSelectedAccount('');
      setSelectedPayable('');
      setStockBalance('');
      setReOrderLevel('');
      setglCode('');
      setSelectedAdvance('');
      setSelectedType('');


      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setCreateLoading(false);
    }
  };







  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);





  const formatPrice = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice(finalValue);
  };

  const formatPrice12 = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice12(finalValue);
  };

  const options = roless.map(item => ({
    label: item.name,
    value: item.id
  }));

  const options1 = tableData3.map(item => ({
    label: item.description,
    value: item.id
  }));

  const fetchIncomes = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setIncomes(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchExpenses = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-category-id?category_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setExpenses(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCostGood = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${28}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setCostGood(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchReceivables = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${11}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setReceivables(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchPayables = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${17}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setPayables(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const salesOptions = incomes.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const cost = costGood.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const disc = tableData2.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const purchaseOptions = expenses.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const receivablesOptions = receivables.map(item => ({
    label: item.gl_name,
    value: item.id
  }));
  const payablesOptions = payables.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const advanceOptions = payables.map(item => ({
    label: item.gl_name,
    value: item.id
  }));


  useEffect(() => {
    if (bearer) {
      fetchIncomes();
      fetchExpenses();
      fetchReceivables();
      fetchPayables();
      fetchCostGood();
    }
  }, [bearer]);

  const extendedSales = [
    { label: '(Add a new sales account)', value: 'add-new' },
    ...salesOptions
  ];

  const extendedCost = [
    { label: '(Add a new cost account)', value: 'add-new' },
    ...cost
  ];
  const extendedPurchase = [
    { label: '(Add a new inventory account)', value: 'add-new' },
    ...purchaseOptions
  ];
  const extendedDisc = [
    { label: '(Add a new purchase account)', value: 'add-new' },
    ...disc
  ];
  const extendedReceivables = [
    { label: '(Add a new receivables account)', value: 'add-new' },
    ...receivablesOptions
  ];
  const extendedPayables = [
    { label: '(Add a new payables account)', value: 'add-new' },
    ...payablesOptions
  ];
  const extendedAdvanced = [
    { label: '(Add a new advanced payment account)', value: 'add-new' },
    ...advanceOptions
  ];

  const handleSales = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedSales(selectedOption.value);
    }
  };
  const handleCost = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedCost(selectedOption.value);
    }
  };
  const handleDisc = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedDisc(selectedOption.value);
    }
  };

  const handlePurchase = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow1();
    } else {
      setSelectedPurchase(selectedOption.value);
    }
  };
  const handleReceivables = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow2();
    } else {
      setSelectedAccount(selectedOption.value);
    }
  };
  const handlePayables = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow3();
    } else {
      setSelectedPayable(selectedOption.value);
    }
  };
  const handleAdvance = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow3();
    } else {
      setSelectedAdvance(selectedOption.value);
    }
  };

  const handleCreate = () => {
    navigate('/inventory/create_product');
  };


  return (
    <div>
      <LatestInventoryDash />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3 style={{ fontSize: '30px', fontWeight: 700, }}>Expired Products</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <div className={classes.formIntBtn}>
                {/* <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Stock</Button> */}
                {/* <Button variant="success" className={classes.btn1} onClick={handleCreate}> Add New Product</Button> */}
                {/* <Button variant="success" className={classes.btn1} onClick={handleShow}> Add New Product</Button> */}
              </div>
            </div>
          </div>
        </div>


        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
          <Modal size="lg" show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Product </Modal.Title>
              </Modal.Header>
                <Modal.Body className="grid-example">
                  <Container>
                    <Row>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                          <div className={`d-flex align-items-left form-group-custom`} >
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Name</Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Description</Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Description</Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Purch. Price</Form.Label>
                            <Form.Control
                              // className={classes.formControl + ' ' + classes.alignRight}
                              type="text"
                              placeholder="Input Purchase Price"                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              onBlur={(e) => formatPrice(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Sale Price</Form.Label>
                            <Form.Control
                              // className={classes.formControl + ' ' + classes.alignRight}
                              type="text"
                              placeholder="0.00"
                              value={price12}
                              onChange={(e) => setPrice12(e.target.value)}
                              onBlur={(e) => formatPrice12(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>UOM</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} options={options} dropdownHeight='100px'
                                placeholder="Select UOM" onChange={setUnit} value={unit}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Category</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={options1} style={{ borderRadius: 5, width: '100%' }}
                                onChange={setSelectedCategory}
                                value={selectedCategory}
                                placeholder="Select Category"                              
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}> Qty in Stock </Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={stockBalance}
                              onChange={(e) => setStockBalance(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className="d-flex align-items-left form-group-custom">
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>
                              Re-Order Lv.
                            </Form.Label>
                            <div style={{ width: '100%' }}>
                            <Form.Control
                              type="text"
                              value={reOderLevel}
                              onChange={(e) => setReOrderLevel(e.target.value)}
                            />
                            {/* <Form.Select
                              value={deprePeriod}
                              onChange={(e) => setDeprePeriod(e.target.value)}
                            >
                              <option value="">Select Depre. Period</option>
                              <option value="1">Monthly</option>
                              <option value="2">Yearly</option>
                            </Form.Select> */}
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Sales GL</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedSales} style={{ borderRadius: 5, width: '100%' }}
                                onChange={handleSales}
                                placeholder="Select Sales GL"                              
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Inventory GL</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedPurchase}
                                onChange={handlePurchase}
                                placeholder="Select Purchases "
                              />
                              </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Cost GL</Form.Label>
                            <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={extendedCost} style={{ borderRadius: 5, }}
                            onChange={handleCost} placeholder="Select Cost of Good"
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Discount GL</Form.Label>
                            <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={extendedDisc}
                            onChange={handleDisc}
                            placeholder="Select Discount GL"
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Receivables</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedReceivables} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                                onChange={handleReceivables} placeholder="Select Receivables"
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Payables </Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedPayables} style={{ borderRadius: 5, width: '100%' }}
                                onChange={handlePayables}
                                placeholder="Select Payables"                              
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Advance Payment</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select
                                loading={isLoading}
                                dropdownHeight="100px"
                                options={extendedAdvanced}
                                placeholder="Select Advance"
                                onChange={handleAdvance}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Display Image</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Form.Control
                              type="file"
                              placeholder="Choose file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleSingleImageChange}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Additional Image</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Form.Control
                              type="file"
                              multiple
                              placeholder="Choose file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleImageChange}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                      </Col>
                    </Row>
                  </Container>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={createUser}>
                      {createLoading ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Creating Product, please wait...</span>
                        </>
                      ) : (
                        "Create Product"
                      )}
                    </Button>
                  </Modal.Footer>
              </Modal.Body>
            </Modal>
            
            <Modal animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Stock Name"
                      // autoFocus
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit of Measurement</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={unit}
                      onChange={handleUnitChange}
                    >
                      <option value="" disabled>Select Unit of Measurement</option>
                      {roless.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter unit price"
                      // autoFocus
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Opening Stock Balance</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Opening Stock Balance"
                      // autoFocus
                      value={stockBalance}
                      onChange={(e) => setStockBalance(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Re-Order Level</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Input Re-Order Level"
                      // autoFocus
                      value={reOderLevel}
                      onChange={(e) => setReOrderLevel(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>GL</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Price"
                      value={glCode}
                      onChange={handleGlChange}
                    >
                      <option value="" disabled>Select GL</option>
                      {tableData2.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.gl_name}
                        </option>
                      ))}


                    </Form.Select>

                    <div style={{ marginTop: 10 }} />

                  </Form.Group>
                </Form>
              </Modal.Body>


              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createUser}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating Item, Please wait...</span>
                    </>
                  ) : (
                    "Create Stock"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title> Edit Items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Stock Name"
                      // autoFocus
                      value={name1}
                      onChange={(e) => setName1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit of Measurement</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={unit1}
                      onChange={handleUnitChange1}
                    >
                      <option value="" disabled>Select Unit of Measurement</option>
                      {roless.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter unit price"
                      // autoFocus
                      value={price1}
                      onChange={(e) => setPrice1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Opening Stock Balance</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Opening Stock Balance"
                      // autoFocus
                      value={stockBalance1}
                      onChange={(e) => setStockBalance1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Re-Order Level</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Input Re-Order Level"
                      // autoFocus
                      value={reOderLevel1}
                      onChange={(e) => setReOrderLevel1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>GL</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Price"
                      value={glCode1}
                      onChange={handleGlChange1}
                    >
                      <option value="" disabled>Select GL</option>
                      {tableData2.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.gl_name}
                        </option>
                      ))}


                    </Form.Select>

                    <div style={{ marginTop: 10 }} />

                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>

                <Button variant="success" onClick={editUser} >
                  {updateLoading ? (<> <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>
                      Updating item, Please wait...
                    </span>
                  </>) : ("Save Changes")}
                </Button>
              </Modal.Footer>
            </Modal>


            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                  {searchLoading ? (
                      <>
                      <Spinner  size='sm' /> 
                     
    </>
  ) : (
                "Search"
              )}
                  </Button> */}
                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching products...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>S/N</th>
                        <th colSpan="2">Name</th>                        
                        <th>Description</th>
                        <th>Expiry Date</th>
                        {/* <th>Unit of Measurement</th> */}
                        <th>Quantity in Stock</th>
                        <th>Re-Order Level</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>
                    {displayedData.length > 0 ? (
                      <>
                      {displayedData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                          <img
                              src={item.image}
                              style={{ width: "50px", height: "50px", objectFit: "cover" }}
                            />
                          </td>
                          <td style={{ whiteSpace: 'wrap', }}>
                            {item.name}                            
                            </td>
                          <td style={{ textAlign: "left", whiteSpace: 'wrap', }}>{item.description}</td>
                          <td style={{ textAlign: "left" }}>{item.expiry_date}</td>
                          {/* <td style={{ textAlign: "left" }}>{item?.measurement?.name}</td> */}
                          <td>{item.quantity}</td>
                          <td>{item.re_order_level}</td>
                          {/* <td>
                            <div onClick={() => handleEyeClick(item.id)} className="mr-1 btn btn-success-soft btn-sm">
                              <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                        </>
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                style={{ textAlign: "center" }}
                                              >
                                                No data available
                                              </td>
                                            </tr>
                                          )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>






    </div >
  )
}