import React, { useState, useEffect } from "react";
import styles from "./AccInfo.module.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Spinner } from "react-bootstrap";
import { useNavigate, NavLink, Link, } from 'react-router-dom';
import {
  Form,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { FiHome } from "react-icons/fi";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ClassNames } from "@emotion/react";
import { BASE_URL } from "../../api/api";
import tick from '../../../assets/promix/tick-circle.png';
import tick2 from '../../../assets/promix/pick2.png';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Swal from "sweetalert2";

const AccInfo = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [plans1, setPlans1] = useState([]);
  const [bearer, setBearer] = useState("");
    const [user, setUser] = useState("");
  const [handleSelectedData, setHandleSelectedData] = useState(false);
  const [selectedPlanMoney, setSelectedPlanMoney] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };



  const fetchPlans = async () => {
    setIsLoading(true);
    try {
      const responses = await axios.get(
        `${BASE_URL}/get-all-plans`
      );
      const planss = responses.data?.data;
      setPlans(planss);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {

        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
      setPlans([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPlans1 = async () => {
    setIsLoading1(true);
    try {
      const responses = await axios.get(
        `${BASE_URL}/get-all-plans`
      );
      const planss = responses.data?.data;
      setPlans1(planss);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {

        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
      setPlans1([]);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);
  
  const handlePlanSelection = (item) => {
    setSelectedPlan(item.name); // Update the selected plan name
    setSelectedPlanMoney(item.yearly); // Update the selected plan name
    setSelectedPlanId(item.id); // Update the selected plan name
    setHandleSelectedData(true);
  };

  useEffect(() => {
    if (handleSelectedData) {
      fetchPlans1();
      setHandleSelectedData(false);
    }
  }, [handleSelectedData]);


  const handlePayment = async () => {
    setIsLoading1(true);
    try {
      const response = await axios.post(`${BASE_URL}/pay-package`, {
        plan_id: selectedPlanId, 
      },
    {
      headers: headers,
    }
    );
  
      // Assuming the API returns a payment URL for Paystack
      const paymentUrl = response.data?.data?.url;
  
      if (paymentUrl) {
        // Redirect to Paystack payment page
        window.location.href = paymentUrl;
      } else {
        console.error("Payment URL not provided in response");
      }
    } catch (error) {
          let errorMessage = "An error occurred. Please try again.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (typeof error.response.data.message === "string") {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join("; ");
            } else if (typeof error.response.data.message === "object") {
              errorMessage = JSON.stringify(error.response.data.message);
            }
          }
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: errorMessage,
          });
          console.error(error);
        } finally {
      setIsLoading1(false);
    }
  };
  


const handleButtonClick = () => {
  if (selectedPlanMoney === "0.00") {
    navigate("/onboarding");
  } else if (selectedPlanMoney === null) {
    toast.success("Please contact us on +2348060202011 for further registration guidelines.");
  } else {
    // Call the Paystack payment endpoint
    handlePayment();
  }
};



  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.accText}>Payment</h1>
        <p className={styles.infoText}>
        Choose the plan that works for you and align to your business
        </p>
      </div>
      <div>
        {/* <p className={styles.specify}>Choose the pricing that works for you and align to your business</p> */}
        {
  isLoading ? (
    <div className={styles.sizeDiv}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
        <Spinner size="lg" style={{ marginTop: '50px' }} />
        {/* <span style={{ marginTop: '10px' }}>Loading plans, Please wait...</span> */}
      </div>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row',  }}>
      {plans.map((item, index) => (
        <div key={index} className={styles.planContainer} >
          {/* <div style={{ display: 'flex',  flexDirection: 'row', width: "100%", backgroundColor: "red" }}> */}
          {/* <div className={styles.size}> */}
          <label htmlFor={`plan-${index}`} className={styles.size} style={{border: selectedPlan === item.name ? "3px dotted #2d995f" : "1px dotted #1b84ff"}}>
              <input
                type="radio"
                id={`plan-${index}`}
                name="plan"
                value={item.name}
                onChange={() => handlePlanSelection(item)}
              /><span>
              <p style={{color: selectedPlan === item.name ? "#2d995f" : "#000"}}>{item.name}</p> </span>
            </label>
          {/* </div> */}
        </div>
      ))}
    </div>
  )
}


{selectedPlan && (
  <p className={styles.srt}>
    Below are the benefits of selecting this plan
  </p>
)}

      </div>

        <div className={styles.pricingOpts}>
                <div>
                  {plans.map((item, index) => (
                  <div key={index} >
                  {selectedPlan === item.name && (                      
                    <div className={styles.pricingDesg} style={{ backgroundColor: '#fff' }}>
                      <div className={styles.pricingPad} >
                        <p className={styles.amount} style={{ color: '#6C757D' }}>
                          {item.no_of_users === ""
                            ? "Contact us"
                            : item.yearly === "0.00"
                              ? `₦0.00`
                              : `₦${parseFloat(item.yearly).toLocaleString("en-US", {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                          {item.no_of_users !== "" && (
                            <span className={styles.amountSpan}>
                              {item.yearly === "0.00" ? "/30 days" : "/year"}
                            </span>
                          )}
                        </p>
                        <p className={styles.useFor} style={{ color: '#6C757D' }}>{item.use}</p>
                        <div className={styles.lists} >
                          {item.priviledges.map((privilege, index) => (
                            <div key={index} className={styles.subLists}>
                              <img src={tick} className={styles.tick} />
                              <p style={{ color: '#6C757D' }}>{privilege}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
                  ))}
                </div>

              {/* )} */}
            </div>
      


            {selectedPlan && (
      <div className={styles.buttonDiv}>
        <button
  className={styles.continue}
  onClick={handleButtonClick}
  disabled={isLoading1} 
>
  {isLoading1 
    ? "Processing your payment..."
    : selectedPlanMoney === null
    ? "Contact Us"
    : selectedPlanMoney === "0.00"
    ? "Pay ₦0.00"
    : `Pay ₦${parseFloat(selectedPlanMoney).toLocaleString("en-US", {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`}
</button>

      </div>
            )}
    </div>
  );
};

export default AccInfo;
