import React, { useState, useEffect } from 'react';
import classes from './BankRecon.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
// import favicon from '../../Images/faviconn.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import CashDash from '../../Cash Dash/CashDash';
import Select from 'react-select';
import PaymentDash from '../../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDash';
import portal from "../../../assets/nw/portal.svg";
import notification from "../../../assets/nw/notification.svg";
import user from "../../../assets/nw/manageUser.svg";
import modules from "../../../assets/nw/modules.svg";
import currency from "../../../assets/nw/currency.svg";
import down from "../../../assets/nw/dropdown.svg";
import plus from "../../../assets/nw/plus.svg";
import chart from "../../../assets/nw/chart.svg"
import vector from "../../../assets/nw/vector.svg";
import linear from "../../../assets/nw/linear.svg";
import s from "../../../assets/nw/delete.svg";
import downs from "../../../assets/nw/down1.svg"
import { Tabs, Tab } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoChatbubblesSharp } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import { FaRegSave } from "react-icons/fa";
import { GrPowerCycle } from "react-icons/gr";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from '../../../Component/Mobile/MobileNav';




export default function PayrollBankReconciliation() {
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);
  const [datesLoading, setDatesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [bearer, setBearer] = useState('');
  const [tableData01, setTableData01] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [step1, setStep1] = useState("");
  const [user, setUser] = useState('');
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose1 = () => setShow1(false);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [selectedBankAc, setSelectedBankAc] = useState('');
  const [selectedBankName, setSelectedBankName] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [date, setDate] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [ledgTableData, setLedgTableData] = useState([]);
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [totalReceipts, setTotalReceipts] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');
  const [step, setStep] = useState("");
  const [userTypes, setUserTypes] = useState('');
  const [admin, setAdmin] = useState('');
  const [openNav, setOpenNav] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();

  }, []);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
    setSelectedBankName(selectedOption.label);
    setSelectedBankAc(selectedOption.acNo);
  };

  const fetchDate = async () => {
    setDateLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/bank_payroll/getMonthAndYear`, { headers });
      const data = response.data?.data;
      console.log(response);
      setDate(data);

      console.log(response.data);

    } catch (error) {
      console.log(error);
      const errorStatus = error.response.data.message;
      toast.error(JSON.stringify(error.response.data.message));
      console.error(errorStatus);
    } finally {
      setDateLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchDate();
    }
  }, [bearer]);


  const fetchDt = async () => {
    setDatesLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/bank_payroll/fetch_bank_payroll?month=${selectedDate}`, { headers });
      const responseData = Array.isArray(response.data?.data) ? response.data?.data : [];
      // const responseData = response.data?.data;
      setTableData(responseData);
      handleClose();
      console.log('Response Data:', responseData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data');
    } finally {
      setDatesLoading(false); // Ensure loading state is turned off
    }
  };

  const fetchExpenses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-expenses`, { headers });

      // console.log(response);
      const results = response.data?.data;
      setTableData(results);
      console.log(results, "heree");
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      // fetchExpenses();
      //   fetchTotalExpenses();
    }
  }, [bearer]);


  const createCategoris = async () => {
    setLoadings(true);
    try {
      const response = await axios.post(`${BASE_URL}/payroll/step/create?description=Step 2&company_id=1`,
        {
          description: step,
          // company_id: selectedCompanyId 
        },
        { headers }
      );
      // setDescription("");
      console.log(response.data.message)
      // fetchData();
      // fetchArchivedData();
      handleClose();
      setStep('');
      // return
      // toast.success(response.data.message);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.data.message;
      toast.error(JSON.stringify(error.response.data.message));
      console.error(errorStatus);
    } finally {
      setLoadings(false);
    }
  };


  const fetchAllowancetyp = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payroll/allowance/type`, {
        params: { company_id: selectedCompanyId },
        headers: headers,
      });
      const results = response.data?.data?.allowanceTypes;
      // console.log(results);
      setTableData01(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData01([]);
    } finally {
      setRoleLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAllowancetyp();

    }
  }, [bearer]);



  const handlePrint = (id) => {
    const selectedLedger = ledgTableData.find(item => item.id === id);


    navigate('/cash_management/reports/revenue_receipt2', { state: { selectedLedger } });
  };


  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);
  };
  // Handle date change
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  console.log(selectedDate);

  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = accounts.reduce((total, item) => total + parseFloat(item.credit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [accounts]);


  const handleBank = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  };

  console.log(selectedBank);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const handleCreate = () => {
    navigate('/payment/create_expenses');
  }



  const filteredData = tableData.filter(item =>
    item.particulars && item.particulars.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const formattedTotalEntries = totalEntries.toLocaleString();


  return (
    <div className={classes.latestBody}>
      {
        openNav && (
          <MobileMenu setOpenNav={setOpenNav} openNav={openNav} />)
      }

      <LatestDash setOpenNav={setOpenNav} openNav={openNav} />

      <div className={classes.finishedbodyCont}>

        <div className={classes.mainasdflkj}>
          <div className={classes.leftasdflkj}>
            <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 0 10px 0  15px" }}>Monthly Payroll</h3>
            <p style={{ margin: " 0 10px 20px 15px" }}>Process monthly payroll</p>
          </div>
          <div className={classes.leftasdflkj1}>
            {/* <div className="form-group row" style={{ margin: " 10px 0 0  0" }}>
              <label for="example-text-input" className={`col-sm-4 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label>
              <div className="col-sm-1" >
                <div style={{ width: 300 }}>
                  <Select

                    options={selectOptions1}
                    placeholder='Select Bank'
                    onChange={handleBankChange}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '200px',
                        // maxWidth: '400px',
                        overflowY: 'auto',
                      }),
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className={classes.iconsStyle}>
          <IoMdCheckmark style={{ color: 'green', cursor: "pointer" }} size={25} />
          <ImCancelCircle style={{ color: 'red', cursor: "pointer" }} size={25} />
          <FaRegSave size={25} style={{ cursor: "pointer" }} />
          <GrPowerCycle style={{ color: 'orange', cursor: "pointer" }} size={25} />
          <FontAwesomeIcon icon={faRightToBracket} style={{ cursor: "pointer", fontSize: '23px' }} onClick={handleShow} />
        </div>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Process</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Date</Form.Label>


                    <Form.Select className="form-control"
                      as="select"
                      value={selectedDate}
                      onChange={handleDateChange}
                    >
                      <option value="" disabled>Select Date</option>
                      {date.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={fetchDt}>
                  {datesLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                    </>
                  ) : (
                    "Process"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <div className={classes.tab1}>

              <div>

                <div className={classes.mainTable}>
                  {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error if any */}
                  {tableData01?.length > 0 && (
                  <div className="table-responsive alltbl" style={{ overflowX: 'auto', maxWidth: '1120px' }} >
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Date</th>
                          <th>Employee</th>
                          <th >Basic</th>

                          {tableData01.map((header, index) => (
                            <th key={index}>{header.description}</th>
                          ))}
                          <th>Total Basic</th>
                          <th>Allowances</th>
                          <th>Total Salary</th>
                          <th>Deductions</th>
                          <th>Net Pay</th>
                          <th>Gross Pay</th>
                        </tr>
                        <tr>
                          {/* {tableData.map((header, index) => (
              <th key={index}>{header}</th>
            ))} */}
                        </tr>
                      </thead>
                      {tableData?.length > 0 && (
                        <tbody style={{ whiteSpace: 'nowrap', }}>
                          {tableData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td style={{ fontSize: 12 }}>{item.month}</td>
                              <td style={{ width: 3, fontSize: 12 }}>{item?.staff?.name}</td>
                              <td style={{ fontSize: 12 }}>{parseFloat(item.basic).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}</td>
                              {/* <td style={{ fontSize: 12, textAlign: "right" }}>{item.employee_id}</td>
                            <td style={{ fontSize: 12, textAlign: "right" }}>{item.basic}</td> */}
                              {tableData01.map((header, headerIndex) => {
                                const matchedItem = item?.details?.find(
                                  (detail) => detail.component_id == header.id && detail.type == "Allowance"
                                );

                                return (
                                  <td key={headerIndex} style={{ fontSize: 12, textAlign: "right" }}>
                                    {matchedItem?.amount ? parseFloat(matchedItem.amount).toLocaleString('en-US', {
                                      minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '0.00'}
                                    {/* {matchedItem ? matchedItem.amount : 0} */}
                                  </td>
                                );
                              })}
                              {/* <td style={{ fontSize: 12, textAlign: "right" }}>{item.basic_salary}</td>
                            <td style={{ fontSize: 12, textAlign: "right" }}>{item.basic_salary}</td>*/}
                              <td style={{ fontSize: 12, textAlign: "right" }}>{parseFloat(item.total_basic).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}</td>
                              <td style={{ fontSize: 12, textAlign: "right" }}> {parseFloat(item.allowances).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              </td>
                              <td style={{ fontSize: 12, textAlign: "right" }}>{parseFloat(item.total_salary).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              </td>
                              <td style={{ fontSize: 12, textAlign: "right" }}>{parseFloat(item.deduction).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}</td>
                              <td style={{ fontSize: 12, textAlign: "right" }}>{parseFloat(item.net_pay).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}</td>
                              <td style={{ fontSize: 12, textAlign: "right" }}>{parseFloat(item.gross_pay).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}</td>
                            </tr>
                          ))}

                          {/* {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))} 
            </tr>*/}
                          {/* ))} */}
                        </tbody>
                      )}
                    </table>
                  </div>
)}
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>


    </div >
  )
}