import React, { useState, useEffect } from 'react';
import classes from './StockDelivery.module.css';
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';

import AsyncStorage from '@react-native-async-storage/async-storage';
import CurrencyInput from 'react-currency-input-field';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestInventoryDash from '../LatestInventoryDash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Select from "react-select";


export default function StockDelivery() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const initialState = () => {
    const unitPrice = '';
    const qty = '';
    return [{ items: '', unitPrice, qty, totalPrice: unitPrice * qty }];
  };
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [roleLoading12, setRoleLoading12] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData12, setTableData12] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [ben, setBen] = useState([]);
  const [orders, setMyOrders] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStoreID, setSelectedStoreID] = useState(null);
  const [totalSupplied, setTotalSupplied] = useState("");
  const [benBank, setBenBank] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [filterLoading, setFilterLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [debitAmount, setDebitAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [roless, setRoless] = useState([]);



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const createPurchaseDelivery123 = async () => {

    setCreateLoading(true);
    try {
      const quantities = formData.map((row) => row.suppliedQuantity).filter((id) => id !== undefined);
      const prices = formData.map((row) => row.suppliedPrice).filter((id) => id !== undefined);
      const amounts = formData.map((row) => row.suppliedAmount).filter((id) => id !== undefined);
      const purchases = orders.map((item) => item.id).filter((id) => id !== undefined);
  
      const formDatas = new FormData();
      formDatas.append("supplier_id", selectedBeneficiary);
      formDatas.append("purchase_order_id", selectedOrderId);
      formDatas.append("quantity_supplied[]", [quantities]);
      formDatas.append("supplied_price[]", [prices]);
      formDatas.append("supplied_amount[]", [amounts]);
      formDatas.append("invoice_id[]", [purchases]);
      if (uploadedFile) {
        formDatas.append("supporting_document", uploadedFile); // Key should match your backend field
      }

      console.log(purchases);
      // console.log(quantities, prices, amounts, selectedBeneficiary, purchases);

      const response = await axios.post(
        `${BASE_URL}/stock-delivery`,
          formDatas,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${bearer}`
            }
          }
        );  
      // navigate(-1);
      // return
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        console.log(errorMessage);
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const createPurchaseDelivery = async () => {
    setCreateLoading(true);
    try {
      const quantities = formData.map((row) => row.suppliedQuantity).filter((id) => id !== undefined);
      const prices = formData.map((row) => row.suppliedPrice).filter((id) => id !== undefined);
      const amounts = formData.map((row) => row.suppliedAmount).filter((id) => id !== undefined);
      const purchases = orders.map((item) => item.id).filter((id) => id !== undefined);
  
      const formDatas = new FormData();
      formDatas.append("supplier_id", selectedBeneficiary);
      formDatas.append("purchase_order_id", selectedOrderId);
      formDatas.append("store_id", selectedStore);
  
      // Append each value in the array individually to ensure it is received as a flat array in FormData
      quantities.forEach((quantity) => formDatas.append("quantity_supplied[]", quantity));
      prices.forEach((price) => formDatas.append("supplied_price[]", price));
      amounts.forEach((amount) => formDatas.append("supplied_amount[]", amount));
      purchases.forEach((invoiceId) => formDatas.append("invoice_id[]", invoiceId));
  
      if (uploadedFile) {
        formDatas.append("supporting_document", uploadedFile); // Key should match your backend field
      }
  
      const response = await axios.post(
        `${BASE_URL}/stock-delivery`,
        formDatas,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
  
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        console.log(errorMessage);
      }
    } finally {
      setCreateLoading(false);
    }
  };
  

  const fetchSupplierss = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const beneficiariess = response.data?.data;

      const options1 = beneficiariess.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setBen(options1);

    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setBen([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    setFilterLoading(true);
    console.log()
    try {
      const response = await axios.get(
        `${BASE_URL}/fetch-invoice-items?invoice_number=${selectedOrder}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      console.log(response)
      const orders = response.data?.data || [];
      console.log(orders, 'ord');

      setMyOrders(orders);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }

    } finally {
      setFilterLoading(false);
    }
  };

  console.log(selectedOrder);

  const fetchBenAcct = async (selectedBeneficiary) => {
    setLoading(true);
    // console.log(selectedBeneficiary)
    try {
      const response = await axios.get(
        `${BASE_URL}/fetch-supplier-pending-invoice?supplier_id=${selectedBeneficiary}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      // console.log(response)
      const paid = response.data?.data || [];
      console.log(paid, 'paid');
      const banks = paid.map((item) => ({
        label: item.invoice_number,
        value: item.invoice_number,
        itemId: item.id
      }));
      setBenBank(banks);
    } catch (error) {
      const errorStatus = error.response.data.message;
      // console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchSupplierss();
    }
  }, [bearer]);

  const goBack = () => {
    navigate(-1);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };


  const handleValueChange = (value, name, index) => {
    const updatedFormData = [...formData];
    if (!updatedFormData[index]) {
      updatedFormData[index] = {};
    }
    updatedFormData[index][name] = value;

    // Calculate the new amount
    const suppliedQuantity = parseFloat(updatedFormData[index].suppliedQuantity || 0);
    const suppliedPrice = parseFloat(updatedFormData[index].suppliedPrice || 0);
    const suppliedAmount = suppliedQuantity * suppliedPrice;

    // Fetch the current row's amount from orders and add it to suppliedAmount
    const currentAmount = parseFloat(orders[index]?.amount || 0);
    const totalAmount = currentAmount + suppliedAmount;

    // Update the state with the new suppliedAmount
    const newFormData = updatedFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, suppliedAmount: suppliedAmount };
      }
      return item;
    });

    setFormData(newFormData);
  };


  const fetchStore = async () => {
    setRoleLoading12(true);
    try {
      const response = await axios.get(`${BASE_URL}/store`, { headers });
      const results = response.data?.data;
      const results12 = response.data?.data;
      // console.log(results);
      setTableData12(results);

      const storeoptions = results12.map((item) => ({
        label: item.store_name,
        value: item.id,
      }));
      setRoless(storeoptions);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData12([]);
      }
    } finally {
      setRoleLoading12(false);
    }
  };

  const calcTotalAmount1 = () => {
    const total = formData.reduce((total, row) => total + parseFloat(row.suppliedAmount) || 0, 0);
    setTotalSupplied(total.toFixed(2))
    // console.log(totalQuantity);
  };

  useEffect(() => {
    calcTotalAmount1();
  }, [formData]);


  const disableButton = debitAmount !== totalAmount;


  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/delivered-purchase-order`, { headers });
      const results = response.data?.data?.orders;
      console.log(response, "lll");
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };






  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchStore();
    }
  }, [bearer]);



  const handleBeneficiaryChange = (selectedOption) => {
    setSelectedBeneficiary(selectedOption.value);
    setSelectedOrder("");
    setTotalSupplied("");
    setFormData([]);
    setMyOrders([]);

  };

  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption.value);
    // setSelectedStoreID(selectedOption.id);


  };

  const handleOrderChange = (selectedOption) => {
    setSelectedOrder(selectedOption.value);
    setSelectedOrderId(selectedOption.itemId);
  };



  useEffect(() => {
    if (bearer && selectedBeneficiary) {
      fetchBenAcct(selectedBeneficiary);
    }
  }, [bearer, selectedBeneficiary]);


  const filteredData = tableData.filter(item => item.supplier?.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);







  const handleCreate = () => {
    navigate('/inventory/create_stock_delivery');
  };










  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  return (
    <div>
      <LatestInventoryDash />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3 style={{ fontSize: '30px', fontWeight: 700, }}>Purchase Order Delivery</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <div className={classes.formIntBtn}>
                <Button variant="success" onClick={handleShow} className={classes.btn2}> Receive Stock</Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.mainform}>

          <Modal size="lg" show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Purchase Delivery</Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
              <Container>
                <Row>
                  <Col xs={12} md={4}>
                  <div className="form-group row justify-content-end">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                        <Form.Label style={{ width: '40%' }}>Supplier</Form.Label>
                        <Select
                          options={ben}
                          onChange={handleBeneficiaryChange}
                          placeholder="select supplier"
                          menuPortalTarget={document.body}
                          styles={{
                            width: "100%",
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                    </Form.Group>
                  </div>
                  </Col>
                  <Col xs={12} md={4}>
                  <div className="form-group row justify-content-end">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{ width: '40%' }}>Order ID:</Form.Label>
                        <Select
                          options={benBank}
                          placeholder="Select Order ID"
                          onChange={handleOrderChange}
                          menuPortalTarget={document.body}
                          styles={{
                            width: "100%",
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',

                            }),
                          }}
                        />
                    </Form.Group>
                  </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 30,
                        marginBottom: 30,
                      }}>
                        <Button style={{ borderRadius: 0 }} disabled={!selectedOrder} onClick={handleClick} variant="success" >
                          {filterLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Filter Order"
                          )}
                        </Button>

                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                  <div className="form-group row justify-content-end">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                        <Form.Label style={{ width: '40%' }}>Store</Form.Label>
                        <Select
                          options={roless}
                          onChange={handleStoreChange}
                          placeholder="select store"
                          menuPortalTarget={document.body}
                          styles={{
                            width: "100%",
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                    </Form.Group>
                  </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group row justify-content-end">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Supporting Doc.:</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={handleImageChange}
                            style={{ width: "250px" }}
                          />
                      </Form.Group>                
                    </div>
                    </Col>
                  </Row>
              <div className="row" style={{ marginTop: 10 }}>
                {/* <div className="col-md-6"> */}
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                    <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                      <tr>

                        <th>Items Description</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Supplied Quantity</th>
                        <th>Supplied Price</th>
                        <th>Supplied Amount</th>

                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                      {orders.map((rowData, index) => (
                        <tr key={index}>
                          <td>{rowData?.item?.name}</td>
                          <td style={{ textAlign: "right" }}>{parseFloat(rowData.item?.price).toLocaleString('en-US', {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</td>
                          <td>{rowData.quantity}</td>

                          <td>
                            <CurrencyInput
                              name='amount'
                              decimalsLimit={2}
                              value={rowData.amount}
                              className="form-control"
                              disabled
                              style={{ textAlign: "right", border: "none", width: '8rem' }}
                            />
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              className="form-control"
                              required=""
                              type="text"
                              onChange={(e) => handleValueChange(e.target.value, "suppliedQuantity", index)}
                              name="supplied-quantity"
                              value={formData[index]?.suppliedQuantity || ""}
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              name="supplied price"
                              decimalsLimit={2}
                              value={formData[index]?.suppliedPrice || ""}
                              className="form-control"
                              onValueChange={(value) => handleValueChange(value, "suppliedPrice", index)}
                              style={{ textAlign: "right", border: "none", width: '8rem' }}
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              name="supplied amount"
                              decimalsLimit={2}
                              value={formData[index]?.suppliedAmount || ""}
                              className="form-control"
                              disabled
                              // onValueChange={(value) => handleValueChange(value, "suppliedAmount", index)}
                              style={{ textAlign: "right", border: "none", width: '8rem' }}
                            />
                          </td>

                        </tr>
                      ))}

                    </tbody>
                  </table>

                </div>
              </div>
              </Container>
              
              <div style={{ marginTop: 10 }} />
              {/* <div className="col-md-12" style={{ display: "flex", width: '100%', padding: "0" }}> */}
                <div className="form-group row justify-content-end">
                  <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Total Supplied Amount:</label>
                  <div className="col-sm-4" style={{ padding: '0', maxWidth: '18.5%', }}>
                    <CurrencyInput
                      name="supplied total"
                      decimalsLimit={2}
                      value={totalSupplied}
                      className="form-control"
                      disabled
                      style={{ textAlign: "right", border: "none", width: '8rem' }}
                    />
                  </div>
                </div>
              {/* </div> */}
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createPurchaseDelivery}>
                  {createLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, please wait...</span>
                    </>
                  ) : (
                    "Process Delivery"
                  )}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <div className={classes.loandgrantcards}>

            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
            </div>



            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      // value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                    // onChange={(e) => {
                    // setSearchTerm(e.target.value);


                    // }}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching data...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Order ID</th>
                        <th>Supplier</th>
                        <th> Supplied Quantity</th>
                        <th> Supplied Price</th>
                        <th> Supplied Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>
                      {displayedData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.order_id}</td>
                          <td>{item.supplier?.name}</td>
                          <td>{item.quantity_supplied}</td>
                          <td style={{ textAlign: "right" }}>{parseFloat(item.supplied_price).toLocaleString('en-US', {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</td>
                          <td style={{ textAlign: "right" }}>{parseFloat(item.supplied_amount).toLocaleString('en-US', {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</td>
                          <td>{formatDate(item.date_supplied)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>






    </div >
  )
}