import React, { useState, useEffect } from "react";
import newClass from './NewSignup.module.css'
import NewLogo from "../../assets/NewSignupImg/NewSignupLogo.svg"
import Form from 'react-bootstrap/Form';
import promix from "../../assets/NewSignupImg/promiximg.png"
// import { Button } from 'react-bootstrap';
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";
import Swal from "sweetalert2";


export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [token, setToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);


  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokens = searchParams.get('token');
    setToken(tokens)
    console.log('Here is your token:', tokens); 
    
    // if (tokens) {
    //     handleForgotPassword(token);
    // }
}, [location.search]);
  

  const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

  const handleNavSignup = () => {
    navigate('/signup'); // Updated navigation
  };


  const handleLogin = async () => {
    setLoading(true);
    try {
        const response = await axios.post(`${BASE_URL}/reset-password`,
            {
                // password: oldPassword,
                   password: newPassword,
                   password_confirmation: confirmPassword,
                   token: token
            }
        );

        Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
              });
        
      navigate('/login');

    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
                    if (error.response && error.response.data && error.response.data.message) {
                      if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                      } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                      } else if (typeof error.response.data.message === 'object') {
                        errorMessage = JSON.stringify(error.response.data.message);
                      }
                      Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: error.response.data.message,
                      });
                     }
    } finally {
        setLoading(false);
    }
}

const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
        handleLogin();
    }
};

const isButtonDisabled = confirmPassword !== newPassword;


  return (
    
    <div className={`${newClass.newSignupBody} ${newClass.newLoginBody}`}>
      <ToastContainer />
        <div className={`${newClass.newSignContainer} ${newClass.loginNewSignContainer}`}>
            <div className={newClass.newSignHeader}>
              <div className={newClass.logo}>
                <NavLink to={'/'} > <img src={NewLogo} className={newClass.img}/> </NavLink>
              </div>
            </div>

            <div className={newClass.newSignSection}>

              <div className={newClass.newSignEndToEnd}>
                <h1>
                  End-to-End Software to Streamline your Accounting processes
                </h1>
                <div className={newClass.newSignObject}>
                    <img src={promix} alt="promix"/>
                </div>
              </div>

              <div className={newClass.newSignFormSection}>
                <div className={`${newClass.formHead} ${newClass.loginFormHead}`}>
                  <h1>Reset your password</h1>
                  <p>The new password must be at least 8 characters</p>
                </div>
                <div className={newClass.mainForm}>
                  <Form className={newClass.loginForm}>
                    {/* <div className={newClass.flexSect}> */}
                      {/* <Form.Group style={{width:'100%'}}>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="name"  onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" className={newClass.inputs} />
                      </Form.Group> */}

                      {/* <Form.Group style={{width:'100%'}}>
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control onChange={(e) => setOldPassword(e.target.value)} type={showPassword ? 'text' : 'password'} className={newClass.inputs}  placeholder="Enter your password" />
                          <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                          </button>
                      </Form.Group> */}
                      <Form.Group style={{width:'100%'}}>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control onChange={(e) => setNewPassword(e.target.value)} type={showPassword1 ? 'text' : 'password'} className={newClass.inputs}  placeholder="Enter your password" />
                          <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility1}
                            >
                                {showPassword1 ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                          </button>
                      </Form.Group>
                      <Form.Group style={{width:'100%'}}>
                        <Form.Label>Confirm your Password</Form.Label>
                        <Form.Control onChange={(e) => setConfirmPassword(e.target.value)} type={showPassword2 ? 'text' : 'password'} className={newClass.inputs}  placeholder="Enter your password" onKeyPress={handleKeyPress} />
                          <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility2}
                            >
                                {showPassword2 ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                          </button>
                      </Form.Group>
                    {/* </div> */}
                    {/* <div className={newClass.flexSect}>
                      <Form.Group>
                        <Form.Label>Work email</Form.Label>
                        <Form.Control className={newClass.inputs} type="email" placeholder="Enter your email address" />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control className={newClass.inputs} type="number" placeholder="Enter your phone number" />
                      </Form.Group>
                    </div>

                    <Form.Group>
                      <Form.Label>Company name</Form.Label>
                      <Form.Control className={newClass.inputs} type="text" placeholder="Agritech" />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Select aria-label="Default select example" className={newClass.inputs}>
                      <option>Nigeria</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Which of our products are you interested in?</Form.Label>
                      <Form.Control className={newClass.inputs} type="number" placeholder="Accounting" />
                    </Form.Group> */}
                    <div className={newClass.newbtns}>
                      <Button  onClick={handleLogin} variant='light' disabled={isButtonDisabled}>
                      {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Reset your password...</span>
                            </>
                        ) : (
                            "Reset Password"
                        )}                        
                      </Button>
                    </div>
                    <div className={newClass.newbtns}>
                      {/* <div>
                            Don't have an Account yet? <a className={newClass.loginlinkclick} onClick={handleNavSignup}>Sign up here</a>
                            <p className={newClass.lgin}>Forgot Password? <Link to={'/forgot_password'} style={{ textDecoration: 'none' }}><span>Reset here!</span></Link></p>
                      </div> */}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
