// import React from 'react'
import classes from './UploadBal.module.css'
import Fullbullets from './BulletTable.svg';
import Backarrow from './Arrwbck.svg';
import { ProgressBar } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../api/api'
import { toast } from 'react-toastify';
import Select from 'react-select';
import Editimg from './Editimg.svg';
import Deleteimg from './Deleteimg.svg';
import Addimg from './Addimg.svg';

const UploadBalanceU = ({ onBack, balance, onGoToNextTab }) => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [benBank, setBenBank] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, beneficiary: '', beneficiaryAccount: '', debitGlCode: '', creditGlCode: '', amount: '', }]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadd, setLoadd] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState('');
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
   const [totalAmountCredit, setTotalAmountCredit] = useState('');
    const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [isEqual, setIsEqual] = useState(false);
  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');

   useEffect(() => {
      // Compare the total debit and total credit values
      setIsEqual(totalAmountDebit === totalAmountCredit);
    }, [totalAmountDebit, totalAmountCredit]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  const uploadExcel = async () => {
    setUploadLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile[0]);

      // console.log(selectedFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${bearer}`,
      };

      const response = await axios.post(
        'https://api-sme.promixaccounting.com/api/v1/payment_voucher/post-journal',
        formData,
        { headers }
      );
      handleClose();
      navigate('/pending_payment_voucher');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      console.log(response.data.message);
    } catch (error) {
      const errorStatus = error.response.data.message;

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setUploadLoading(false);
    }
  };


  const handlePosting = async () => {
    setLoadd(true);
    try {

        // const accountIds = balance.map((row) => row.id).filter((id) => id !== undefined);
  
        const response = await axios.post(
            `${BASE_URL}/account/save-opening-balance`,
            {
              total_debit: totalAmountDebit,
          total_credit: totalAmountCredit,
          uuid: balance[0]?.uuid
            },
            { headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`
            }
         }
        );

        console.log(response.data);
        // if (response.data && response.data.message) {
        //     toast.success(response.data.message);
        // } else {
        //     toast.success('Data saved successfully');
        // }            
        
        // toast.success(response.data.message);
        // console.log(response.data);           

        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
        });
       
        onGoToNextTab();
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: JSON.stringify(error.response.data.message),
        });
        //   toast.error(errorMessage);
          console.log(error);
        }
    } finally {
        setLoadd(false);
    }
};
  const fetchBenAcct = async (selectedBeneficiary) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/beneficiaryaccounts/getaccount?beneficiary_id=${selectedBeneficiary}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      const paid = response.data?.data || [];
      const benAcc = paid.map((item) => ({
        label: item.bank_name,
        value: item.id,
      }));
      console.log(paid, 'paid');
      setBenBank(benAcc);
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const handleFormChange = (selectedOption, fieldName, rowIndex) => {
    if (fieldName === 'beneficiary') {
      fetchBenAcct(selectedOption.value);
    }

    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      updatedFormData[rowIndex] = {
        ...updatedFormData[rowIndex],
        [fieldName]: selectedOption
      };
      return updatedFormData;
    });
  };


  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };





  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData1(debitAcct);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAsset = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/accounts`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const assetsAcc = response.data?.data;
      const options2 = assetsAcc.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData2(assetsAcc);
      setSelectOptions2(options2);


    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
      fetchDebit();
      fetchAsset();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      beneficiary: '',
      beneficiaryAccount: '',
      debitGlCode: '',
      creditGlCode: '',
      amount: '',
    };

    setFormData(prevFormData => [...prevFormData, newRow]);
  };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFile(fileList);

  };

  const calculateTotalAmount = () => {
    const total = formData.reduce((total, row) => total + parseFloat(row.amount) || 0, 0);
    setTotalAmount(total);
    // console.log(totalQuantity);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [formData]);

  const calculateTotalDebit = () => {
      const total = balance.reduce((acc, item) => {
        return item.debit ? acc + parseFloat(item.debit || 0) : acc;
      }, 0);
  
      const formattedTotal1 = total.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  
      setTotalAmountDebit(formattedTotal1);
    };
    const calculateTotalCredit = () => {
      const total1 = balance.reduce((acc, item) => {
        return item.credit ? acc + parseFloat(item.credit || 0) : acc;
      }, 0);
  
      const formattedTotal11 = total1.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  
      setTotalAmountCredit(formattedTotal11);
    };
  
  
  
  
    useEffect(() => {
      calculateTotalDebit();
      calculateTotalCredit();
  
    }, [balance]);

    

  return (
    <div className={classes.maincont1234}>
          <div className={classes.formSection2345}>
            <h4>Upload Initial Balances</h4>
            <p className={classes.marginbottomp}> Upload your initial balances</p>
            <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto', }}>
              <table className="table display table-striped table-hover bg-white m-0 card-table">
                <thead className={classes.tableheadbackgroundcolor}>
                  <tr>
                    <th style={{ backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>S/N</th>
                    <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Account Code</th>
                    <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Account Name</th>
                    <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Transaction Date</th>
                    <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Debit</th>
                    <th style={{  backgroundColor: "#164B2E", color: "#FFFFFF", textAlign: "left" }}>Credit</th>
                    {/* <th style={{ backgroundColor: "#164B2E", color: "#FFFFFF" }}><div onClick={() => addRow()}>
                      <img src={Addimg} alt='icon' className={classes.Imageshowdfsf213} />
                    </div></th> */}
                  </tr>
                </thead>
                <tbody style={{ whiteSpace: "nowrap", width: "100%" }}>
                  {balance.map((row, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: 'left',  width: "20%" }}>{index + 1}</td>
                      <td style={{ textAlign: 'left',   width: "20%" }}>
                        {row.gl_code}
                        {/* <Form.Control type="text" placeholder="Enter account code" /> */}
                      </td>
                      <td style={{ textAlign: 'left',   width: "20%" }}>
                      {row.description}
                        {/* <Form.Control type="text" placeholder="Enter account name" /> */}
                      </td>
                      <td style={{ textAlign: 'left',   width: "20%" }}>
                      {row.transaction_date}
                        {/* <Form.Control type="text" placeholder="Enter account name" /> */}
                      </td>
                    
                      <td style={{ textAlign: "right", paddingRight: 10 }}>{parseFloat(row.debit).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                          </td>
                                          <td style={{ textAlign: "right", paddingRight: 10 }}>{parseFloat(row.credit).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                          </td>
                      {/* <td style={{ textAlign: "left" }}>
                        <div onClick={() => deleteRow(index)}>
                          <img src={Deleteimg} alt='icon' className={classes.Imageshowdfsf213} />
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
              <div style={{ marginTop: 20 }} />
                            <div className={classes.form_align}>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Debit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountDebit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right', width: 300 }}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Credit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountCredit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right', width: 300 }}
                                />
                              </Form.Group>
                            </div>
<div style={{marginTop: 20, cursor: "pointer"}}>
  <p onClick={onBack}>Go Back</p>
</div>
<div style={{marginTop: 30}}>
  <Button variant='success'  disabled={!isEqual} className={classes.btnFt} onClick={handlePosting}>
     {loadd ? (
                                      <>
                                        <Spinner size='sm' />
                                        <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                                      </>
                                    ) : (
                                      "Next"
                                    )}
  </Button>
</div>
            
          </div>
    </div>

  )
};


export default UploadBalanceU;