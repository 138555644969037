import React, { useState, useEffect, useRef } from 'react';
import classes from './UploadAccounts.module.css'
import SideImg from '../../assets/TeamMemberImg/TeamMemberSideImg.svg'
import TopImage from './TopImage.svg';
import Bullets from './Bullets.svg';
import Profilessss from './down.svg';
import Fullbullets from './addteam.svg';
import Backarrow from './Arrwbck.svg';
import { ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';


export default function UploadAccounts({onNext, onBack}) {
  const handleUploadSuccess = () => {
    // Logic for successful upload
    onNext();
  };
  const handleGoback = () => {
    // Logic for successful upload
    onBack();
  };
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [Loading12, setSkipLoading12] = useState(false);
  const [admin, setAdmin] = useState("");
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


  const fileInputRef = useRef();

  const handleDivClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'xlsx' || fileExtension === 'xls' || fileExtension === 'csv') {
        setFileName(file.name); // Update state with the file name
        setErrorMessage(null); // Clear any previous error messages
        console.log('File uploaded:', file);
      } else {
        setFileName(null); // Clear file name if invalid
        setErrorMessage('Please upload a valid Excel or CSV file.'); // Set error message
      }
    }
  };

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/users/fetch-all`, { headers });
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      setTableData(results);
      console.log(response);
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);

  const handleSkip = async () => {
    setSkipLoading12(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/authorize`,
        { usertype: 1 },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          },
        }
      );
      console.log('User switched:', response.data);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      navigate('/welcome');

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data),
        });
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setSkipLoading12(false);
    }
  };


  return (
    <div className={classes.maincont1234}>
      <div className={classes.formSection}>
        {/* <div className={classes.textMain}>
          <p>Complete your account setup</p>
        </div> */}
        <div className={classes.normdfalp}>

         

          <div className={classes.formSection2345}>
            {/* <img src={TopImage} alt='icon' className={classes.Imageshow213} /> */}
            <h4>Set up Chart of Accounts</h4>
            <p>Add your chart of account</p>

            <a href="https://account-api.patna.ng/api/v1/download-journal-entries" download>
                          <Button
                            className={classes.btn1}
                            style={{ width: 200, height: "30px", padding: "0", borderRadius: 5, marginTop: 20 }} variant='success' >
                            Download Template
                          </Button>
                        </a>
            {/* <h4>Set up Chart of Accounts</h4> */}
              <div className={classes.boxes} onClick={handleDivClick} style={{ cursor: 'pointer' }}>

                <img src={Profilessss} alt='icon' className={classes.Imageshowdfsf213} />
                <div>
                  {/* <h5>Members</h5> */}
                  <h6 style={{fontSize: 14, color: "#6C757D", fontWeight: 400}}>Upload CSV or Excel file of Chart of Account </h6>
                  {fileName && <p style={{ marginTop: '10px', fontStyle: 'italic', color: 'green'}}>Uploaded file: {fileName}</p>}
                  {errorMessage && <p style={{ marginTop: '10px', fontStyle: 'italic', color: 'red' }}>{errorMessage}</p>}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </div>
              <div >
           <p 
            onClick={onBack} 
           style={{
            marginTop: 10,
            cursor: "pointer"
           }}>
             Go Back
            </p>
            </div>
            <Button disabled={roleLoading} variant="success" className={classes.snupbtnreddfa} onClick={handleUploadSuccess}>
               Next
            </Button>
          </div>
        </div>
      </div>
    </div>

  )
}
