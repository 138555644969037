import React, { useState, useEffect } from 'react';
import classes from './Return.module.css';
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';

import AsyncStorage from '@react-native-async-storage/async-storage';
import CurrencyInput from 'react-currency-input-field';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestInventoryDash from '../LatestInventoryDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from "react-select";



export default function ReturnInward() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [roless, setRoless] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [outstanding, setOutstanding] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [amountToPay, setAmountToPay] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [reason, setReason] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [tableData2, setTableData2] = useState([]);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-returned-goods`, 
        {
        params: {
          type: 2
        },
        headers });
      const results = response.data?.data;
      console.log(results, "return outward");
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData3 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


  const handleDebitChange = (event) => {
    setSelectedDebitAccount(event.target.value);
  }


  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchData3();
    }
  }, [bearer]);





  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {

    const foundInvoice = tableData.find(item => item.id === id);
    //  console.log(foundInvoice);
    navigate('/accounting/update_purchase_order_account', { state: { foundInvoice } });

    setEyeClicked(true);
  };


    const handleReturnOutward = async () => {
    setCreateLoading(true);
    try {
      const formDatas = new FormData();
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      formDatas.append('item.id', selectedProduct?.value || '');
      formDatas.append('description', description);
      formDatas.append('reason', reason);
      formDatas.append('amount', amount);
      formDatas.append('transaction_date', date);
      formDatas.append('type', '2');
      formDatas.append('quantity', quantity || '');
      // formDatas.append('price', removeFormatting(price) || '');      
      const response = await axios.post(
        `${BASE_URL}/return-goods`,
       formDatas,
       {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${bearer}`
        }
      }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      setSelectedProduct('');
      setDescription('');
      setReason('');
      setQuantity('');
      setAmount('');
      setDate('');

      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const productList = response.data?.data;
      // console.log(results);
      setProducts(productList);
      console.log(productList);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchItems();
    }
  }, [bearer]);


  const options = products.map(item => ({
    label: item.name,
    value: item.id
  }));




  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this invoice.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/role/delete-role?role_id=${id}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handlePrintInvoice = (id) => {
    const selectedInvoice = tableData.find(item => item.id === id);


    navigate('/accounting/print_purchases_order', { state: { selectedInvoice } });
  };

  const handleSalesChange = (event) => {
    const selectedId = event.target.value;
    setSelectedInvoice(selectedId);

    const intselectedId = parseInt(selectedId);
    const selectedInvoice = roless.find(item => item.id == intselectedId);
    setDescription(selectedInvoice?.description || '');
    setAmount(selectedInvoice?.amount || '');
    setOutstanding(selectedInvoice.balance || '');

  };

  const formattedAmount = isNaN(parseFloat(amount)) ? '0.00' : parseFloat(amount).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedOutstanding = isNaN(parseFloat(outstanding)) ? '0.00' : parseFloat(outstanding).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const handleValueChange = (value, name, values) => {
    setAmount(value);

  };

  const handleCreate = () => {
    navigate('/inventory/returning_outward');
  };




  const createPayment = async () => {
    setPaymentLoading(true);

    try {
      let requestData = {
        id: selectedInvoice,
        debit_gl_code: selectedDebitAccount,
        // credit: selectedCreditAccount,
        amount: amountToPay || '',
      };



      console.log(selectedInvoice, requestData);
      const response = await axios.post(
        `${BASE_URL}/pay-purchase-invoice`,
        requestData,
        { headers }
      );

      toast.success(response.data.message);
      fetchData();
      //  fetchData1();
      handleClose();

      setSelectedInvoice('');
      setAmountToPay('');
      setSelectedDebitAccount('');
      setDescription('');
      setAmount('');
      setTotalAmount('');
      setOutstanding('');

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };


  return (
    <div>
      {/* <MainDashoard /> */}

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <LatestInventoryDash />
          {/* <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Purchase Invoice</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
            </div>
          </div> */}
        </div>

        {/* <div className={classes.analysis}>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL INCOME</p>
                        <h5>N232,096,635.05</h5>
                        <div className={classes.perceCont}>
                            <p className={classes.percent}><img src={Arrow} alt="arrowDown"/> 5%</p>
                            <p>vs average</p>
                        </div>
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL LODGE</p>
                        <h5>N232,096,635.05</h5>
                        <div className={classes.perceCont}>
                            <p className={classes.percent}><img src={Arrow} alt="arrowDown"/> 5%</p>
                            <p>vs average</p>
                        </div>
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL OUTSTANDING</p>
                        <h5>N232,096,635.05</h5>
                        <div className={classes.perceCont}>
                            <p className={classes.percent}><img src={Arrow} alt="arrowDown"/> 5%</p>
                            <p>vs average</p>
                        </div>
                    </div>
                </div> */}

        <div className={classes.topSpace}>
          <div className={classes.laondetheadTopadfCards}>
            <div className={classes.laondetheadTopCards121}>
              <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  12px" }}>Return Outward</h3>
              <p style={{ margin: " 0 10px 20px 12px" }}>Add, View, Edit, and Delete Return Outward</p>
            </div>
            <div className={classes.laondetheadTopCards}>

              <div className={classes.card}>
                <p>Total Return Outward Value</p>
                <h4>0.00</h4>
              </div>

              <div className={classes.card}>
                <p>Total Number of Return Outward</p>
                <h4>0.00</h4>
              </div>

            </div>
          </div>
          <div className={classes.mainform}>

            <div className={classes.loandgrantcards}>
              <div className={classes.loandethead}>
                {/* <div className={classes.formLabel}>
                </div> */}
                <div className={classes.laondetheadTopCards}>

                  {/* <div className={classes.card}>
                    <p>Total Purchase Order Value</p>
                    <h4>0.00</h4>
                  </div>

                  <div className={classes.card}>
                    <p>Total Number of Purchase Order</p>
                    <h4>0.00</h4>
                  </div> */}

                </div>
                <div className={classes.formIntBtn}>
                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add retun outward</Tooltip>}
                  >
                    <Button variant="success" onClick={handleCreate} className={classes.btn2}> Add New Return Outward</Button>
                  </OverlayTrigger>

                  {/* <Button variant="primary" onClick={handleShow} className={classes.btn1}> Make Payment</Button> */}
                </div>
              </div>

              <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Add New Return Outward</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ marginTop: 20 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div style={{ marginTop: 10 }} />
                      <Form.Label>Product</Form.Label>
                      <Select loading={isLoading} options={options} dropdownHeight='100px'
                        placeholder="select Product" onChange={setSelectedProduct} value={selectedProduct}
                      />
                      <div style={{ marginTop: 10 }} />
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        // autoFocus
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />

                      <div style={{ marginTop: 10 }} />
                      <Form.Label>Reason</Form.Label>
                      <Form.Control
                        as="textarea"
                        // autoFocus
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                      <div style={{ marginTop: 10 }} />
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        // autoFocus
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        // value={formattedOutstanding}
                        // onChange={(e) => setOutstanding(e.target.value)}
                      />

                      <div style={{ marginTop: 10 }} />
                      <Form.Label>Amount</Form.Label>
                      <CurrencyInput
                        //   
                        name="amount-to-pay"
                        decimalsLimit={2}
                        className="form-control"
                        value={amount} // Set the value to the balance state
                        onValueChange={handleValueChange}
                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                      />
                      <div style={{ marginTop: 10 }} />
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>


                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button variant="success" onClick={handleReturnOutward}>
                    {createLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>Adding, Please wait...</span>
                      </>
                    ) : (
                      "Add Return Outward"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                  <div className={classes.actionsBtns}>
                    <Button variant='success'>Copy</Button>
                    <Button variant='success'>Excel</Button>
                    <Button variant='success'>PDF</Button>
                    <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                  </div>
                  <div className={classes.show}>
                    <label className="d-flex justify-content-start align-items-center">
                      Show
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                      //  value={entriesPerPage}
                      //     onChange={(e) => {
                      //     setEntriesPerPage(parseInt(e.target.value));
                      //     setCurrentPage(1);
                      //     }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div className="text-right modal-effect ">
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mr-2">Search:</div>
                      <input
                        type="search"
                        value={searchTerm}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={classes.mainTable}>
                {roleLoading ? (
                  <p>Fetching returning outward products...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Product</th>
                          <th>Description</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                          <th>Reason</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap', }}>
                        {displayedData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.item?.name}</td>
                            <td style={{ textAlign: "left" }}>{item.description}</td>
                            <td>{item.quantity}</td>
                            <td style={{ textAlign: "right" }}>{parseFloat(item.amount).toLocaleString('en-US', {
                              minimumIntegerDigits: 1,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</td>
                            <td>{item.reason}</td>
                            <td>{item.transaction_date}</td>
                            <td>
                              <div className="mr-1 btn btn-success-soft btn-sm">
                                <i className="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                              <div className="btn btn-danger-soft btn-sm">
                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                              {/* <div onClick={() => handlePrintInvoice(item.id)} className="btn btn-sm printbtninv">
                                <i className="fa fa-print dawg" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                              </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>






    </div >
  )
}